const VK_PREFIX = "vk_";

export const pathToSite = "https://bp.dnzg.dev";
export const urlImage = new URL("https://bp-assets.dnzg.ru");
export const url = new URL(window.location.href.toString());
export const sign = url.searchParams.get("sign") as string;

const params = url.searchParams;

export const authorization = params
    .toString()
    .split("&")
    .filter((p) => p.startsWith(VK_PREFIX))
    .sort()
    .join("&");

const vk_platform = url.searchParams.get("vk_platform") as string;
const mobile_platforms = ["mobile_web", "mobile_iphone", "mobile_android"];

export const isMobile = mobile_platforms?.includes(vk_platform);
export const urlLeaderboard = new URL(
    `${pathToSite}/api/getleaderboard?format=json`
);
export const urlFaq = new URL(`${pathToSite}/api/getfaq?format=json`);
export const urlHub = new URL(`${pathToSite}/api/getallpublic?format=json`);
export const urlCreateUser = new URL(`${pathToSite}/api/createuser`);
export const urlGetUser = new URL(`${pathToSite}/api/getuser`);

export const vkId = url.searchParams.get("vk_user_id") as string;

export const urlDate = new URL(`${pathToSite}/api/getdate?format=json`);
export const urlTasks = new URL(`${pathToSite}/api/gettask?vk_id=${vkId}`);
export const urlCheckTask = new URL(
    `${pathToSite}/api/checktask?vk_id=${vkId}&task_id=`
);
export const urlHelp = new URL(`${pathToSite}/api/help`);
export const urlAbout = new URL(`${pathToSite}/api/about`);
export const urlChangePublic = new URL(
    `${pathToSite}/api/changepublic?vk_id=${vkId}&public_id=`
);
export const urlCheckChangePublic = new URL(
    `${pathToSite}/api/checkchangepublic?vk_id=${vkId}&public_id=`
);
export const urlGetSpecialTask = new URL(
    `${pathToSite}/api/getspecialtask?vk_id=${vkId}`
);
export const urlQuestion = new URL(`${pathToSite}/api/getquestion`);
export const urlPrizes = new URL(`${pathToSite}/api/getprizes?vk_id=${vkId}`);
export const urlOldTasks = new URL(
    `${pathToSite}/api/getoldtask?vk_id=${vkId}`
);
export const urlTurnir = new URL(`${pathToSite}/api/gettimeline`);
export const urlTimeline = new URL(`${pathToSite}/api/gettimelinedescription`);
export const urlCheckSpecial = new URL(
    `${pathToSite}/api/participateindraw?vk_id=${vkId}&task_id=`
);
export const urlFutureTasks = new URL(
    `${pathToSite}/api/getfuturetask?vk_id=${vkId}`
);
export const urlGetChange = new URL(`${pathToSite}/api/getdatachange`);
export const urlFaffleWin = new URL(
    `${pathToSite}/api/fafflewin?vk_id=${vkId}`
);
export const urlFaffle = new URL(`${pathToSite}/api/faffle`);
export const urlRequired = new URL(`${pathToSite}/api/getmandatorytasks`);
export const urlSpecialModal = new URL(`${pathToSite}/api/getspecialmodalka`);
export const urlSpecialBanner = new URL(`${pathToSite}/api/getspecialbanner`)