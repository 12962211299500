import React from "react";

import styles from "./systemAlerts.module.css";
import { urlImage } from "../../utils/api/apiUrl";

interface SystemAlertsInterface {
    subtitle: string;
    imgSrc?: string;
    children?: React.ReactNode;
    canChange?: boolean;
}

function SystemAlerts({
    subtitle,
    imgSrc = `${urlImage}/sistem.svg`,
    children,
    canChange,
}: SystemAlertsInterface) {
    return (
        <div className={styles.popup}>
            {!canChange ? (
                <div className={styles.wrapper}>
                    <div className={styles.alert_container}>
                        <img className={styles.wrapper_image} src={imgSrc} />
                        <div dangerouslySetInnerHTML={{ __html: subtitle }} />
                    </div>
                    <div className={styles.triangle}></div>
                </div>
            ) : null}
            {children}
        </div>
    );
}

export default React.memo(SystemAlerts);
