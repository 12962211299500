import { useState, useEffect } from "react";
import { urlRequired, authorization, sign } from "../../utils/api/apiUrl";

const useArrayState = () => {
    const [arrayState, setArrayState] = useState<number[]>([]);

    useEffect(() => {
        fetch(`${urlRequired}`, {
            method: "POST",
            headers: {
                Authorization: `${authorization}`,
                Sign: `${sign}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setArrayState(data.number);
            })
            .catch((error) => {
                console.error("Ошибка:", error);
            });
    }, []);

    return arrayState;
};

export default useArrayState;
