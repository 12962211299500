import React from 'react';
import styles from './ModalHub.module.css';
import { urlImage } from '../../utils/api/apiUrl';

interface Props {
    show: any;
    setShow: any;
}

export const ModalHub: React.FC<Props> = ({ show, setShow }) => {
    return (
        <div className={styles.container} style={{
            backgroundImage: `url(${urlImage}mobile1.png)`,
            backgroundPosition: 'bottom center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        }}>
            <img
                src={`${urlImage}close.svg`}
                alt="button close"
                className={styles.close}
                onClick={() => {
                    setShow(false);
                }} />
            <div className={styles.card}>{show}</div>
        </div>
    )
}