import React, { useCallback, useContext, useEffect, useState } from "react";
import { Context } from "../../context";
import {
    authorization,
    sign,
    urlCheckTask,
    urlGetUser,
    vkId,
} from "../../utils/api/apiUrl";
import ModalTask from "../ModalTask/ModalTask";
import { SystemDisDays } from "../SystemDisDays/SystemDisDays";
import { useMobile } from "../hooks/useMobile";
import styles from "./Challenges.module.css";

interface Props {
    title: string;
    description: string;
    cost?: number;
    regularity?: any;
    active: boolean;
    url: string;
    number: number;
    ended: boolean;
    done: boolean;
    doneDays?: number;
    totalDays?: number;
    id: number;
    countDone?: any;
    future?: boolean;
    modalHelp?: any;
    setModalHelp?: any;
}

export const Challenges: React.FC<Props> = ({
    title,
    description,
    cost,
    regularity,
    active,
    url,
    number,
    ended,
    done,
    doneDays,
    totalDays,
    id,
    countDone,
    future,
    modalHelp,
    setModalHelp,
}) => {
    const mobile = useMobile();
    const { setScoreCurrent } = useContext(Context);

    const [isCheck, setIsCheck] = useState(false);
    const [myTaskState, setMyTaskState] = useState(false);
    const [showAlert, setShowAlert]: any = useState(false);
    const [mayShowAlert, setMayShowAlert] = useState(false);
    const [show, setShow] = useState(false);
    const [numberAdd, setNumberAdd] = useState(false);

    const handleModalHelp = useCallback(
        () => setModalHelp(!modalHelp),
        [modalHelp]
    );

    async function checkTask(taskId: any) {
        await fetch(`${urlCheckTask}${taskId}`, {
            method: "POST",
            headers: {
                Authorization: `${authorization}`,
                Sign: `${sign}`,
            },
        })
            .then((response) => {
                if (response.ok) {
                    setMyTaskState(true);
                } else {
                    setMyTaskState(false);
                    setIsCheck(false);
                    setShowAlert(
                        <SystemDisDays
                            subtitle={
                                <div className={styles.alert}>
                                    Что-то пошло не так. Проверьте, что ваша
                                    страница открыта. Пожалуйста, ознакомьтесь с
                                    инструкцией, чтобы убедиться, что все
                                    правильно:{" "}
                                    <span
                                        className={styles.link_help}
                                        onClick={() => {
                                            handleModalHelp();
                                        }}
                                    >
                                        Правила
                                    </span>
                                    .
                                </div>
                            }
                        />
                    );
                }
            })
            .catch((error) => {
                console.error("Ошибка:", error);
            });
    }

    async function checkScore() {
        await fetch(`${urlGetUser}?vk_id=${vkId}`, {
            method: "POST",
            headers: {
                Authorization: `${authorization}`,
                Sign: `${sign}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (setScoreCurrent) setScoreCurrent(data.score_fibonacci);
            })
            .catch((error) => {
                console.error("Ошибка:", error);
            });
    }

    useEffect(() => {
        if (showAlert) {
            setTimeout(setShowAlert, 3000, false);
        }
    }, [showAlert]);

    return (
        <div className={styles.weekly_container}>
            {active && (
                <div className={styles.container}>
                    <div className={styles.container_inner}>
                        <div>
                            <div
                                className={styles.title}
                                dangerouslySetInnerHTML={{ __html: title }}
                            />
                            <div
                                className={styles.description}
                                dangerouslySetInnerHTML={{
                                    __html: description,
                                }}
                            />
                        </div>
                        <div className={styles.footer}>
                            <div className={styles.cost}>
                                +<div className={styles.value}>CP</div>
                                {cost}
                            </div>
                            {(regularity && !countDone) ||
                            (regularity &&
                                countDone == "Выполнено None из None") ? (
                                <div className={styles.regularity}>
                                    {regularity}
                                </div>
                            ) : null}
                            {doneDays && totalDays && !countDone ? (
                                <div className={styles.regularity}>
                                    {doneDays}/{totalDays} дней
                                </div>
                            ) : null}
                            {countDone !== "Выполнено None из None" &&
                            countDone !== null ? (
                                <div className={styles.regularity}>
                                    {countDone}
                                </div>
                            ) : null}
                            {!isCheck &&
                            !myTaskState &&
                            !done &&
                            number !== 9 &&
                            number !== 8 &&
                            url ? (
                                <a
                                    href={url}
                                    className={styles.button}
                                    target="_blank"
                                    onClick={() => {
                                        setIsCheck(true);
                                    }}
                                >
                                    Выполнить
                                </a>
                            ) : null}
                            {done ? (
                                <div className={styles.checked}>Выполнено</div>
                            ) : null}
                            {isCheck &&
                            !myTaskState &&
                            !done &&
                            number !== 9 &&
                            number !== 8 ? (
                                <div
                                    className={styles.button_check}
                                    onClick={() => {
                                        checkTask(id);
                                        setTimeout(() => {
                                            checkScore();
                                        }, 1000);
                                        setMayShowAlert(true);
                                    }}
                                >
                                    Проверить
                                </div>
                            ) : null}
                            {number !== 9 &&
                            number !== 8 &&
                            myTaskState &&
                            !done ? (
                                <div className={styles.checked}>Выполнено</div>
                            ) : null}
                            {number === 13 &&
                            !myTaskState &&
                            !done &&
                            !numberAdd ? (
                                <div
                                    className={styles.button}
                                    onClick={() => {
                                        setShow(true);
                                        setNumberAdd(true);
                                    }}
                                >
                                    Выполнить
                                </div>
                            ) : null}
                            {(number === 8 || number === 9) &&
                            !done &&
                            !numberAdd ? (
                                <div
                                    className={styles.button}
                                    onClick={() => {
                                        setShow(true);
                                        setNumberAdd(true);
                                    }}
                                >
                                    Пригласить
                                </div>
                            ) : null}
                            {numberAdd &&
                            !done &&
                            (number === 8 || number === 9 || number === 13) ? (
                                <div
                                    className={styles.button_check}
                                    onClick={() => {
                                        checkTask(id);
                                        setTimeout(() => {
                                            checkScore();
                                        }, 1000);
                                        setTimeout(() => {
                                            setNumberAdd(false);
                                        }, 600);
                                        setMayShowAlert(true);
                                    }}
                                >
                                    Проверить
                                </div>
                            ) : null}
                            {show ? (
                                <ModalTask
                                    mobile={mobile}
                                    setShow={setShow}
                                    number={number}
                                    link={url}
                                />
                            ) : null}
                            {showAlert && !done && mayShowAlert ? (
                                <div className={styles.alert_check}>
                                    {showAlert}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            )}
            {!active && (
                <div className={styles.container_dis}>
                    <div>
                        <div
                            className={styles.title_dis}
                            dangerouslySetInnerHTML={{ __html: title }}
                        />
                        <div
                            className={styles.description_dis}
                            dangerouslySetInnerHTML={{ __html: description }}
                        />
                    </div>
                    <div className={styles.footer_dis}>
                        {cost != 0 ? (
                            <div className={styles.cost_dis}>
                                +<div className={styles.value_dis}>CP</div>{" "}
                                {cost}
                            </div>
                        ) : null}
                        {regularity && !countDone ? (
                            <div className={styles.regularity_dis}>
                                {regularity}
                            </div>
                        ) : null}
                        {doneDays && totalDays && !countDone ? (
                            <div className={styles.regularity}>
                                {doneDays}/{totalDays} дней
                            </div>
                        ) : null}
                        {countDone ? (
                            <div className={styles.regularity}>{countDone}</div>
                        ) : null}
                        {!ended && future ? (
                            <button type="button" className={styles.button_dis}>
                                Недоступно
                            </button>
                        ) : null}
                        {ended && done && !future ? (
                            <div className={styles.checked}>Выполнено</div>
                        ) : null}
                        {!ended && !done && !future ? (
                            <div className={styles.checked}>Не выполнено</div>
                        ) : null}
                    </div>
                </div>
            )}
        </div>
    );
};
