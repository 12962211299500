import React, { useState, useEffect } from "react";
import styles from "./Tasks.module.css";
import { TasksPage } from "../../TasksPage/TasksPage";
import { Week } from "../../Week/Week";
import { useRecalculateTasks } from "./recalculateTasks";

interface Props {
  currentDay: number;
  currentWeek: number;
  weeks: number;
}

const Tasks: React.FC<Props> = ({ currentDay, currentWeek, weeks }) => {
  const [selected, setSelected] = useState(currentWeek);
  const [activeDay, setActiveDay] = useState(1);
  const [activeDayCurrentWeek, setActiveDayCurrentWeek] = useState(currentDay);

  const { allTasks, allTasksWeekly, bonusTasks } = useRecalculateTasks();

  useEffect(() => {
    setSelected(currentWeek);
    setActiveDayCurrentWeek(currentDay);
  }, [currentWeek, currentDay]);

  return (
    <div
      id="tab-content-tasks"
      aria-labelledby="tab-tasks"
      role="tabpanel"
      className={styles.container}
    >
      <div
        id="tab-content-members"
        aria-labelledby="tab-members"
        role="tabpanel"
        className={styles.page}
      >
        <Week
          currentWeek={currentWeek}
          setSelected={setSelected}
          selected={selected}
          weeks={weeks}
        />

        <div>
          <TasksPage
            active={selected === currentWeek}
            currentWeek={currentWeek}
            selected={selected}
            bonusTasks={bonusTasks}
            currentDay={currentDay}
            activeDayCurrentWeek={activeDayCurrentWeek}
            setActiveDayCurrentWeek={setActiveDayCurrentWeek}
            dataTasks={
              allTasks[selected === currentWeek ? currentWeek : selected] ||
              null
            }
            dataTasksWeekly={allTasksWeekly[selected] || null}
            setActiveDay={setActiveDay}
            activeDay={activeDay}
          />
        </div>
      </div>
    </div>
  );
};

export default Tasks;
