import { useState, useEffect } from "react";
import {
    urlTasks,
    urlOldTasks,
    urlFutureTasks,
} from "../../../utils/api/apiUrl";
import useApiData from "../../hooks/useApiData";

const allTasks = (dataTasks: any, oldTasks: any, futureTasks: any) => {
    let allTasks: { [index: string]: any } = {};

    for (const taskType in dataTasks) {
        dataTasks[taskType].forEach((task: any) => {
            if (!allTasks[task.week]) {
                allTasks[task.week] = {};
            }
            if (!allTasks[task.week][task.day]) {
                allTasks[task.week][task.day] = {};
            }
            if (!allTasks[task.week][task.day][taskType]) {
                allTasks[task.week][task.day][taskType] = [];
            }

            allTasks[task.week][task.day][taskType].push(task);
        });
    }
    for (const taskType in oldTasks) {
        oldTasks[taskType].forEach((task: any) => {
            if (!allTasks[task.week]) {
                allTasks[task.week] = {};
            }
            if (!allTasks[task.week][task.day]) {
                allTasks[task.week][task.day] = {};
            }
            if (!allTasks[task.week][task.day][taskType]) {
                allTasks[task.week][task.day][taskType] = [];
            }

            allTasks[task.week][task.day][taskType].push(task);
        });
    }
    for (const taskType in futureTasks) {
        futureTasks[taskType].forEach((task: any) => {
            if (!allTasks[task.week]) {
                allTasks[task.week] = {};
            }
            if (!allTasks[task.week][task.day]) {
                allTasks[task.week][task.day] = {};
            }
            if (!allTasks[task.week][task.day][taskType]) {
                allTasks[task.week][task.day][taskType] = [];
            }

            allTasks[task.week][task.day][taskType].push(task);
        });
    }

    return allTasks;
};

const allTasksWeekly = (dataTasks: any, oldTasks: any, futureTasks: any) => {
    let allTasksWeekly: { [index: string]: any } = {};
    for (const taskType in dataTasks) {
        dataTasks[taskType].forEach((task: any) => {
            if (!allTasksWeekly[task.week]) {
                allTasksWeekly[task.week] = {};
            }
            if (!allTasksWeekly[task.week][taskType]) {
                allTasksWeekly[task.week][taskType] = [];
            }

            allTasksWeekly[task.week][taskType].push(task);
        });
    }
    for (const taskType in oldTasks) {
        oldTasks[taskType].forEach((task: any) => {
            if (!allTasksWeekly[task.week]) {
                allTasksWeekly[task.week] = {};
            }
            if (!allTasksWeekly[task.week][taskType]) {
                allTasksWeekly[task.week][taskType] = [];
            }

            allTasksWeekly[task.week][taskType].push(task);
        });
    }
    for (const taskType in futureTasks) {
        futureTasks[taskType].forEach((task: any) => {
            if (!allTasksWeekly[task.week]) {
                allTasksWeekly[task.week] = {};
            }
            if (!allTasksWeekly[task.week][taskType]) {
                allTasksWeekly[task.week][taskType] = [];
            }

            allTasksWeekly[task.week][taskType].push(task);
        });
    }

    return allTasksWeekly;
};

export const useRecalculateTasks = () => {
    const dataTasks = useApiData(urlTasks);
    const oldTasks = useApiData(urlOldTasks);
    const futureTasks = useApiData(urlFutureTasks);

    const [bonusTasks, setBonusTasks]: any = useState();

    useEffect(() => {
        if (!Array.isArray(dataTasks) && dataTasks.Bonus[0]) {
            setBonusTasks(dataTasks.Bonus);
        }
    }, [dataTasks]);

    return {
        bonusTasks,
        allTasks: allTasks(dataTasks, oldTasks, futureTasks),
        allTasksWeekly: allTasksWeekly(dataTasks, oldTasks, futureTasks),
    };
};
