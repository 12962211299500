import React, { useState, useEffect } from "react";
import styles from './Question.module.css';
import { urlImage } from "../../utils/api/apiUrl";
import cn from 'classnames';

interface Props {
    title: string;
    text: string;
}
export const Question: React.FC<Props> = ({ title, text }) => {
    const [show, setShow] = useState(false);
    const img = `${urlImage}vector_faq.svg`;
    const [isActive, setActive] = useState(false);

    return (
        <div className={styles.container}>
            <div className={styles.active} onClick={() => {
                if (show) {
                    setShow(false);
                    setActive(false)
                } else {
                    setShow(true);
                    setActive(true);
                };
            }}>
                <img src={img} className={cn(
                    styles.image,
                    isActive && styles.image_active
                )} />
                <div className={styles.item_title}>{title}</div>
            </div>
            {show &&
                <div className={styles.item_paragraph} dangerouslySetInnerHTML={{ __html: text }} />
            }
        </div>
    )
}

{/* <div key={item.title} className={styles.item_container} onClick={() => {
                setShow(true)
            }}>
                <h4  >{item.title}</h4>
                {show && 
                    <p className={styles.item_paragraph}>{item.text}</p>
                }
            </div> */}