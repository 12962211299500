import React from 'react';
import styles from './SystemDisDays.module.css';

interface Props {
  subtitle: any;
  img?: any;
}
export const SystemDisDays = ({ subtitle, img }: Props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.alert_container}>
        {img ? (<div className={styles.wrapper_image}>
          {img}
        </div>) : null}
        <div>{subtitle}</div>
      </div>
      <div className={styles.triangle}></div>
    </div>
  )
}