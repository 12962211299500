import React, {useState} from 'react';
import styles from './Button.module.css';

interface Props {
    name: string;
    url?: any;
    urlHub?: any;
}

export const Button: React.FC<Props> = ({name, url, urlHub}) => {
    return(
            <div className={styles.container}>
                <div className={styles.name}>{name}</div>
                <div className={styles.button_mobile}>
                    <a href={`${url}`} className={styles.button_sub_m} target='_blank'>Подписаться</a>
                    <a href={`${urlHub}`} className={styles.button_hub_m} target='_blank'>Перейти в хаб</a>
                </div>
            </div>
    )
}