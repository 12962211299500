import { useState, useEffect } from "react";
import styles from "./Banner.module.css";
import { pathToSite } from "../../utils/api/apiUrl";
import SystemAlerts from "../SystemAlerts";

export type PropsBanner = {
  title: string;
  text: string;
  text_for_alert: string;
  status: boolean;
  img: string;
  url_hub: string | null;
};

export const Banner: React.FC<PropsBanner> = ({ title, text, img, url_hub, text_for_alert}) => {
  const [mayShowAlert, setMayShowAlert] = useState(true);
  
  useEffect(() => {
    if (url_hub == null) {
      setMayShowAlert(true);
    } else {
      setMayShowAlert(false);
    }
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.container_inner}>
        <div
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <div
          className={styles.text}
          dangerouslySetInnerHTML={{ __html: text }}
        />
        {url_hub == null  && mayShowAlert ? (
          <SystemAlerts subtitle={text_for_alert}>
          <div className={styles.button_dis}>
            Играть в хабе
          </div>
          </SystemAlerts>
        ) : null}
        {url_hub !== null ? (
          <a href={url_hub} target="_blank" className={styles.button}>
            Играть в хабе
          </a>
        ) : null}
      </div>
      {img ? <div className={styles.image_container}>
        <img src={`${pathToSite}${img}`} alt="bloger" className={styles.image}/>
      </div> : null}
    </div>
  );
};
