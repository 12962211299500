import React, { useState, useEffect, useContext } from "react";
import styles from "./TasksPage.module.css";
import { Day } from "../Day/Day";
import { ActiveTasks } from "../ActiveTasks/ActiveTasks";
import { DayDis } from "../DayDis/DayDis";
import useApiData from "../hooks/useApiData";
import {
  urlGetSpecialTask,
  urlSpecialBanner,
  urlSpecialModal,
} from "../../utils/api/apiUrl";
import { Banner, PropsBanner } from "../Banner/Banner";
import { Context } from "../../context";
import { ModalUpdate } from "../ModalUpdate/ModalUpdate";
import { DAYS } from "../../utils/const";

interface Props {
  active: boolean;
  currentWeek: number;
  selected: number;
  currentDay: number;
  activeDayCurrentWeek: number;
  setActiveDayCurrentWeek: React.Dispatch<React.SetStateAction<number>>;
  dataTasks: any;
  setActiveDay: any;
  activeDay: any;
  bonusTasks?: any;
  dataTasksWeekly?: any;
}

export const TasksPage = ({
  active,
  currentWeek,
  selected,
  currentDay,
  activeDayCurrentWeek,
  setActiveDayCurrentWeek,
  dataTasks,
  bonusTasks,
  dataTasksWeekly,
  setActiveDay,
  activeDay, //for disDay
}: Props) => {
  const firstWeek = [1, 2, 3, 4, 5, 6];
  const specialData = useApiData(urlGetSpecialTask);
  const banner = useApiData(urlSpecialBanner);
  const modal = useApiData(urlSpecialModal);
  const { showModal } = useContext(Context);
  const [bannerData, setBannerData] = useState<PropsBanner>(banner);
  const [mayShowModal, setMayShowModal] = useState(false);

  useEffect(() => {
    if (banner[0]) {
      setBannerData(banner[0]);
    }
  }, [banner]);

  useEffect(() => {
    if (modal[0]) {
      setMayShowModal(modal[0].status);
    }
  }, [modal]);

  return (
    <div>
      <div className={styles.text}>
        Регистрируйся на отборочные матчи.
        <br />5 лучших игроков будут представлять сообщества
      </div>
      <div className={styles.list}>
        {active && currentWeek != 1
          ? DAYS?.map((item: any) => (
              <Day
                day={item}
                currentDay={currentDay}
                key={item}
                activeDayCurrentWeek={activeDayCurrentWeek}
                setActiveDayCurrentWeek={setActiveDayCurrentWeek}
              />
            ))
          : null}
        {active && currentWeek == 1
          ? firstWeek?.map((item: any) => (
              <Day
                day={item}
                currentDay={currentDay}
                key={item}
                activeDayCurrentWeek={activeDayCurrentWeek}
                setActiveDayCurrentWeek={setActiveDayCurrentWeek}
              />
            ))
          : null}
        {!active && selected != 1 ? (
          <div className={styles.list}>
            {DAYS.map((item: any) => (
              <DayDis
                day={item}
                key={item}
                setActiveDay={setActiveDay}
                activeDay={activeDay}
                selected={selected}
                currentWeek={currentWeek}
              />
            ))}
          </div>
        ) : null}
        {!active && selected == 1 ? (
          <div className={styles.list}>
            {firstWeek.map((item: any) => (
              <DayDis
                day={item}
                key={item}
                setActiveDay={setActiveDay}
                activeDay={activeDay}
                selected={selected}
                currentWeek={currentWeek}
              />
            ))}
          </div>
        ) : null}
      </div>
      {!showModal && mayShowModal ? <ModalUpdate /> : null}
      {bannerData.status ? <Banner {...bannerData} /> : null}

      <ActiveTasks
        dataTasks={
          !active
            ? dataTasks?.[activeDay]?.Daily
            : dataTasks?.[activeDayCurrentWeek]?.Daily
        }
        active={active}
        currentDay={currentDay}
        activeDayCurrentWeek={activeDayCurrentWeek}
        selected={selected}
        activeDay={activeDay}
        bonusTasks={bonusTasks ?? null}
        weeklyTasks={dataTasksWeekly?.Weekly ?? null}
        timesTasks={dataTasksWeekly?.["Number of times"] ?? null}
      />
    </div>
  );
};
