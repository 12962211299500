import React, { useEffect, useState, useContext, useCallback } from "react";
import styles from "./TaskDaily.module.css";
import {
    urlCheckTask,
    sign,
    authorization,
    vkId,
    urlGetUser,
} from "../../utils/api/apiUrl";
import { SystemDisDays } from "../SystemDisDays/SystemDisDays";
import { Context } from "../../context";

interface Props {
    title: any;
    text: any;
    cost: any;
    check: any;
    active: boolean;
    url: string;
    number: number;
    done: boolean;
    ended: boolean;
    id: number;
    modalHelp?: any;
    setModalHelp?: any;
    currentDay: number;
    activeDayCurrentWeek: number;
    arrayStateNumber: boolean;
}

export const TaskDaily: React.FC<Props> = ({
    title,
    text,
    cost,
    check,
    active,
    url,
    number,
    done,
    ended,
    id,
    modalHelp,
    setModalHelp,
    currentDay,
    activeDayCurrentWeek,
    arrayStateNumber,
}) => {
    const [isCheck, setIsCheck] = useState(false);
    const [myTaskState, setMyTaskState] = useState(false);
    const [showAlert, setShowAlert]: any = useState(false);
    const { setScoreCurrent } = useContext(Context);
    const [requiredTask, setRequiredTask] = useState(false);
    const [checkedRequired, setCheckedRequired] = useState(false);
    const [activeDaily, setActiveDaily] = useState(true);

    useEffect(() => {
        if (currentDay == activeDayCurrentWeek && active) {
            setActiveDaily(true);
        } else if (currentDay != activeDayCurrentWeek || !active) {
            setActiveDaily(false);
        }
    }, [activeDayCurrentWeek]);

    useEffect(() => {
        if (arrayStateNumber && done) {
            setCheckedRequired(true);
        }
    }, [arrayStateNumber]);

    const handleModalHelp = useCallback(
        () => setModalHelp(!modalHelp),
        [modalHelp]
    );

    async function checkTask(taskId: any) {
        await fetch(`${urlCheckTask}${taskId}`, {
            method: "POST",
            headers: {
                Authorization: `${authorization}`,
                Sign: `${sign}`,
            },
        })
            .then((response) => {
                if (response.ok) {
                    setMyTaskState(true);
                    if (arrayStateNumber) {
                        setRequiredTask(true);
                    }
                } else {
                    setMyTaskState(false);
                    setIsCheck(false);
                    setShowAlert(
                        <SystemDisDays
                            subtitle={
                                <div className={styles.alert}>
                                    Что-то пошло не так. Проверьте, что ваша
                                    страница открыта. Пожалуйста, ознакомьтесь с
                                    инструкцией, чтобы убедиться, что все
                                    правильно:{" "}
                                    <span
                                        className={styles.link_help}
                                        onClick={() => {
                                            handleModalHelp();
                                        }}
                                    >
                                        Правила
                                    </span>
                                </div>
                            }
                        />
                    );
                }
            })
            .catch((error) => {
                console.error("Ошибка:", error);
            });
    }

    async function checkScore() {
        await fetch(`${urlGetUser}?vk_id=${vkId}`, {
            method: "POST",
            headers: {
                Authorization: `${authorization}`,
                Sign: `${sign}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (setScoreCurrent) setScoreCurrent(data.score_fibonacci);
            })
            .catch((error) => {
                console.error("Ошибка:", error);
            });
    }

    useEffect(() => {
        if (showAlert) {
            setTimeout(setShowAlert, 3000, false);
        }
    }, [showAlert]);

    return (
        <div
            className={
                requiredTask
                    ? `${styles.none}`
                    : checkedRequired
                    ? `${styles.none_done}`
                    : undefined
            }
        >
            {activeDaily ? (
                <div className={styles.container}>
                    <div className={styles.header}>
                        <div
                            className={styles.title}
                            dangerouslySetInnerHTML={{ __html: title }}
                        />
                        <div
                            className={styles.text}
                            dangerouslySetInnerHTML={{ __html: text }}
                        />
                    </div>
                    <div className={styles.footer}>
                        <div className={styles.cost}>
                            +<div className={styles.value}>CP</div>
                            {cost}
                        </div>
                        <div className={styles.span}>Ежедневно</div>
                        {!isCheck && !myTaskState && !done && url ? (
                            <a
                                href={url}
                                className={styles.button}
                                target="_blank"
                                onClick={() => {
                                    setIsCheck(true);
                                }}
                            >
                                {check}
                            </a>
                        ) : null}
                        {done ? (
                            <div className={styles.checked}>Выполнено</div>
                        ) : null}
                        {isCheck && !myTaskState && !done ? (
                            <div
                                className={styles.button_check}
                                onClick={() => {
                                    checkTask(id);
                                    setTimeout(() => {
                                        checkScore();
                                    }, 1000);
                                }}
                            >
                                Проверить
                            </div>
                        ) : null}
                        {myTaskState && !done ? (
                            <div className={styles.checked}>Выполнено</div>
                        ) : null}
                        {!done || myTaskState ? (
                            <div className={styles.alert_check}>
                                {showAlert}
                            </div>
                        ) : null}
                    </div>
                </div>
            ) : null}
            {!activeDaily && (
                <div className={styles.container_dis}>
                    <div className={styles.header}>
                        <div
                            className={styles.title_dis}
                            dangerouslySetInnerHTML={{ __html: title }}
                        />
                        <div
                            className={styles.text_dis}
                            dangerouslySetInnerHTML={{ __html: text }}
                        />
                    </div>
                    <div className={styles.footer}>
                        <div className={styles.cost}>
                            +<div className={styles.value}>CP</div>
                            {cost}
                        </div>
                        <div className={styles.span}>Ежедневно</div>
                        {!ended ? (
                            <button type="button" className={styles.button_dis}>
                                Недоступно
                            </button>
                        ) : null}
                        {ended && done ? (
                            <div className={styles.checked}>Выполнено</div>
                        ) : null}
                        {ended && !done ? (
                            <div className={styles.checked}>Не выполнено</div>
                        ) : null}
                    </div>
                </div>
            )}
        </div>
    );
};
