import React, { useState } from "react";
import styles from "./Leaderboard.module.css";
import { LeagueHeader } from "../../LeagueHeader/LeagueHeader";
import { urlLeaderboard } from "../../../utils/api/apiUrl";
import useApiData from "../../hooks/useApiData";
import { LEAGUES } from "../../../utils/const";

const Leaderboard: React.FC = () => {
  const [selected, setSelected]: any = useState(LEAGUES[0].select);
  const dataLeaderboard = useApiData(urlLeaderboard);

  return dataLeaderboard ? (
    <div
      id="tab-content-leaderboard"
      aria-labelledby="tab-leaderboard"
      role="tabpanel"
      className={styles.container}
    >
      {LEAGUES.map((league) => (
        selected === league.select ? 
        <LeagueHeader
        key={selected}
        setSelected={setSelected}
        title={league.title}
        dataLeaderboard={dataLeaderboard[league.league]}
        before={league.before}
        after={league.after}
        number={league.number}
      /> : null
      ))}
    </div>
  ) : null;
};

export default Leaderboard;
