import { useState } from "react";

export const useMobile = () => {
    const [mobile, setMobile] = useState(
        window.matchMedia("(max-width: 480px)").matches
    );

    window.addEventListener("resize", () => {
        setMobile(window.matchMedia("(max-width: 480px)").matches);
    });

    return mobile;
};
