export const HELP_HTML = `<div><a href='https://dnzg.ru/community-hub-cup-rules.pdf' target="_blank">Правила</a>  проведения Community Hub Cup</div>`;

export const HEADERS = [
    {
        title: "Главная",
        id: "tab-main",
        aria: "tab-content-main",
        select: "main",
    },
    {
        title: "Задания",
        id: "tab-tasks",
        aria: "ttab-content-tasks",
        select: "tasks",
    },
    {
        title: "Лидерборд",
        id: "tab-leaderboard",
        aria: "tab-content-leaderboard",
        select: "leaderboard",
    },
];

export const LEAGUES = [
    {
        title: "Лига А",
        id: "tab-leagueA",
        aria: "tab-content-leagueA",
        select: 0,
        before: 2,
        after: 1,
        number: "Сообщества от 500 тыс. участников",
        league: "A"
    },
    {
        title: "Лига B",
        id: "tab-leagueB",
        aria: "tab-content-leagueB",
        select: 1,
        before: 0,
        after: 2,
        number: "Сообщества от 100 до 499 тыс. участников",
        league: "B"
    },
    {
        title: "Лига С",
        id: "tab-leagueC",
        aria: "tab-content-leagueC",
        select: 2,
        before: 1,
        after: 0,
        number: "Сообщества до 100 тыс. участников",
        league: "C"
    },
];

export const DAYS = [1, 2, 3, 4, 5, 6, 7];
