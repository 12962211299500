import React, { useEffect, useState, useContext } from "react";
import { useRouteNavigator } from "@vkontakte/vk-mini-apps-router";
import { Panel, PanelHeader } from "@vkontakte/vkui";
import { routes } from "../../utils/routes";
import {
  urlHub,
  urlImage,
  pathToSite,
  urlChangePublic,
  authorization,
  sign,
  urlCheckChangePublic,
  isMobile,
  urlCreateUser,
  vkId,
  urlGetUser,
} from "../../utils/api/apiUrl";
import useApiData from "../../components/hooks/useApiData";
import { ModalHub } from "../../components/ModalHub/ModalHub";
import styles from "./Hub.module.css";
import bridge from "@vkontakte/vk-bridge";
import { Context } from "../../context";
import { useMobile } from "../../components/hooks/useMobile";

interface Props {
  nav: string;
}

const Hub: React.FC<Props> = ({ nav }) => {
  const routeNavigator = useRouteNavigator();
  const { dataUser, setDataUser } = useContext(Context);
  const [dataHub, setDataHub] = useState([]);
  const [show, setShow]: any = useState(false);
  const [publicId, setPublicId] = useState(dataUser.public_id);
  const newDataHub = useApiData(urlHub);
  const mobile = useMobile();
  const [canChange, setCanChange] = useState(false);
  const [isSecond, setIsSecond] = useState(false);
  const url = window.location.href.toString();
  const searchRef = new URLSearchParams(url);
  const [ref, setRef] = useState("");

  useEffect(() => {
    if (searchRef.has("ref")) {
      setRef(`&ref_id=${searchRef.get("ref")}`);
    }
  }, []);

  useEffect(() => {
    if (dataUser.public_id) {
      setIsSecond(true);
    } 
  }, [dataUser.public_id]);

  useEffect(() => {
    function checkChange() {
      if (publicId) {
        fetch(`${urlCheckChangePublic}${publicId}`, {
          method: "POST",
          headers: {
            Authorization: `${authorization}`,
            Sign: `${sign}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            setCanChange(data.status);
          })
          .catch((error) => {
            console.error("Ошибка:", error);
          });
      }
    }
    checkChange();
  }, [publicId]);

  function changeUserInfo() {
    fetch(`${urlGetUser}?vk_id=${vkId}`, {
      method: "POST",
      headers: {
        Authorization: `${authorization}`,
        Sign: `${sign}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setDataUser(data);
      })
      .catch((error) => {
        console.error("Ошибка:", error);
      });
  }

  function setSeenIntro() {
    bridge
      .send("VKWebAppStorageSet", {
        key: "seen_intro",
        value: "true",
      })
      .then((data) => {
        if (data.result) {
          // Значение переменной задано
        }
      })
      .catch((error) => {
        // Ошибка
        console.log(error);
      });
  }

  async function fetchData(url: string) {
    await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `${authorization}`,
        Sign: `${sign}`,
        "Content-Type": "application/json",
      },
    }).catch((error) => {
      console.error("Ошибка:", error);
    });
  }

  async function createUser(publicId: any) {
    if (publicId) {
      await fetch(
        `${urlCreateUser}?vk_id=${vkId}&public_id=${publicId}${ref}`,
        {
          method: "POST",
          headers: {
            Authorization: `${authorization}`,
            Sign: `${sign}`,
          },
          body: JSON.stringify({
            vk_id: parseInt(vkId),
            public_id: parseInt(publicId),
          }),
        }
      );
    }
  }

  useEffect(() => {
    setDataHub(newDataHub);
  }, [newDataHub]);

  function parseNumberToStringValue(number: number) {
    const stringNumber = number.toString();
    let result = "";

    for (let i = stringNumber.length - 1; i >= 0; i--) {
      const needDivider = (stringNumber.length - i) % 3 === 0;
      result = (needDivider ? " " : "") + stringNumber[i] + result;
    }

    return result;
  }

  function getElements(data: any) {
    const parseHub = data.map((item: any) => {
      const sub = parseNumberToStringValue(item.subscriptions);

      return (
        <div
          className={styles.container_item}
          key={item.vk_id}
          onClick={() => {
            setShow(
              <div className={styles.card}>
                <img
                  src={`${pathToSite}${item.image}`}
                  className={styles.icon}
                />
                <div className={styles.description}>Команда сообщества</div>
                <div className={styles.name}>{item.name}</div>
                <div className={styles.sub}>{sub} участников</div>
                <button
                  type="button"
                  onClick={() => {
                    setPublicId(item.vk_id);
                    if (!isSecond) {
                      setTimeout(() => {
                        createUser(item.vk_id);
                      }, 300);
                    }
                    if (isSecond) {
                      if (canChange) {
                        fetchData(`${urlChangePublic}${item.vk_id}`);
                      }
                    }
                    changeUserInfo();
                    setTimeout(() => {
                      routeNavigator.push(
                        routes.default_root.default_view.home_panel
                      );
                    }, 700);
                    setSeenIntro();
                  }}
                  className={styles.button_sub}
                >
                  Вступить
                </button>
              </div>
            );
          }}
        >
          <img
            src={`${pathToSite}${item.image}`}
            className={styles.mini_icon}
          />
          <div className={styles.item}>Команда {item.name}</div>
        </div>
      );
    });
    return <div className={styles.list}>{parseHub}</div>;
  }

  return (
    <Panel nav={nav}>
      {isMobile ? (
        <PanelHeader separator={false} fixed={true} style={{ zIndex: "1000" }}>
          Community Hub Cup
        </PanelHeader>
      ) : null}
      <div
        style={{
          backgroundImage: `url(${urlImage}dota1.png)`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <div className={styles.container}>
          <div className={styles.title_container}>
            <h2 className={styles.title}>Выбери сообщество</h2>
            {isSecond ? (
              <div
                className={styles.cancel_container}
                onClick={() => {
                  routeNavigator.push(
                    routes.default_root.default_view.home_panel
                  );
                }}
              >
                <div className={styles.cancel_title}>Отмена</div>
                <img
                  src={`${urlImage}close.svg`}
                  alt="button close"
                  className={styles.close_button}
                />
              </div>
            ) : null}
          </div>
          <div className={styles.container_content}>
            <div className={styles.container_inner}>{getElements(dataHub)}</div>
            {show && !mobile ? (
              <div className={styles.icon_hub}>
                <div>{show}</div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {show && mobile ? <ModalHub show={show} setShow={setShow} /> : null}
    </Panel>
  );
};

export default Hub;
