import { useState, useEffect, useContext } from "react";
import {
    Panel,
    PanelHeader,
    ScreenSpinner,
    SplitLayout,
} from "@vkontakte/vkui";
import { isMobile } from "../../utils/api/apiUrl";
import bridge from "@vkontakte/vk-bridge";
import { routes } from "../../utils/routes";
import { useRouteNavigator } from "@vkontakte/vk-mini-apps-router";
import { Context } from "../../context";

interface LoadPanelProps {
    nav: string;
}

export const LoadPanel = ({ nav }: LoadPanelProps) => {
    const [storageKey, setStorageKey] = useState("false");
    const [popout, setPopout]: any = useState(null);
    const routeNavigator = useRouteNavigator();
    const clearPopout = () => setPopout(null);
    const { dataUser } = useContext(Context);

    const setLoadingScreenSpinner = () => {
        setPopout(<ScreenSpinner state="loading" />);

        setTimeout(clearPopout, 3000);
    };

    useEffect(() => {
        setLoadingScreenSpinner();

        document.onload = () => {
            clearPopout();
        };
    }, []);

    bridge
        .send("VKWebAppStorageGet", {
            keys: ["seen_intro"],
        })
        .then((data) => {
            setStorageKey(data.keys[0].value);
        })
        .catch((error) => {
            // Ошибка
            console.log(error);
        });

    useEffect(() => {
        if (storageKey === "true" || dataUser.public_id) {
            routeNavigator.push(routes.default_root.default_view.home_panel);
        } else {
            routeNavigator.push(routes.default_root.default_view.intro_panel);
        }
    }, [storageKey, dataUser]);

    return (
        <SplitLayout popout={popout} aria-live="polite" aria-busy={!!popout}>
            <Panel nav={nav}>
                {isMobile ? (
                    <PanelHeader
                        separator={false}
                        fixed={true}
                        style={{ zIndex: "1000" }}
                    >
                        Community Hub Cup
                    </PanelHeader>
                ) : null}
            </Panel>
        </SplitLayout>
    );
};
