import React, { useState, useEffect, useCallback } from "react";
import { TrophyCard } from "../../TrophyCard/TrophyCard";
import { Faq } from "../../Faq/Faq";
import { routes } from "../../../utils/routes";
import styles from "./Main.module.css";
import { ModalFaffle } from "../../ModalFaffle/ModalFaffle";
import {
  urlImage,
  urlFaffle,
  urlFaffleWin,
  authorization,
  sign,
} from "../../../utils/api/apiUrl";
import Timeline from "../../../panels/Timeline";
import ModalPage from "../../ModalPages/ModalPage";
import { TrophyItems } from "../../TrophyItems/TrophyItems";
import { ModalWinners } from "../../ModalWinners/ModalWinners";
import { HELP_HTML, LEAGUES } from "../../../utils/const";
import { useMobile } from "../../hooks/useMobile";
import useApiData from "../../hooks/useApiData";
interface Props {
  dataDay: { day: number; week: number };
  dataAbout: { html: string };
  getPrizes: {
    id: number;
    league: string;
    text: string;
    image: string;
    url_task: string;
    first: string;
    second: string;
    third: string;
    additional_text: string;
  }[];
  dataTimeline: { id: number; week: string; day: string; subheading: string }[];
  dataTurnir: { id: number; title: string; text: string }[];
}

const Main: React.FC<Props> = ({
  dataDay,
  dataAbout,
  getPrizes,
  dataTimeline,
  dataTurnir,
}) => {
  const [selected, setSelected] = React.useState(LEAGUES[0].select);
  const [modalHelp, setModalHelp] = React.useState(false);
  const [modalAbout, setModalAbout] = React.useState(false);
  const [win, setWin] = useState(false);
  const faffle = useApiData(urlFaffle);
  const [faffleBoolean, setFaffleBoolean] = useState(false);
  const mobile = useMobile();

  useEffect(() => {
    if (faffle) {
      setFaffleBoolean(faffle.status);
    }
  }, [faffle]);

  useEffect(() => {
    async function checkWin() {
      await fetch(urlFaffleWin, {
        method: "POST",
        headers: {
          Authorization: `${authorization}`,
          Sign: `${sign}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error === "no win") {
            setWin(false);
          } else if (data.url) {
            setWin(true);
          }
        })
        .catch((error) => {
          setWin(false);
        });
    }
    checkWin();
  }, []);

  const handleModalHelp = useCallback(
    () => setModalHelp(!modalHelp),
    [modalHelp]
  );
  const handleModalAbout = useCallback(
    () => setModalAbout(!modalAbout),
    [modalAbout]
  );

  return (
    <div
      id="tab-content-main"
      aria-labelledby="tab-main"
      role="tabpanel"
      className={styles.container}
    >
      <div>
        {win ? (
          <div className={styles.winners}>
            <ModalWinners />
          </div>
        ) : null}
        {faffleBoolean ? (
          <div className={styles.faffle}>
            <ModalFaffle />
          </div>
        ) : null}
        <div className={styles.container_title}>
          {modalHelp && (
            <ModalPage
              title="Правила"
              subtitle={HELP_HTML}
              handleClick={handleModalHelp}
              mobile={mobile}
            />
          )}
          {modalAbout && (
            <ModalPage
              title="О проекте"
              subtitle={dataAbout.html}
              handleClick={handleModalAbout}
              mobile={mobile}
            />
          )}
          <div className={styles.title}>Призы участникам</div>
          <div className={styles.text}>
            Помоги своему сообществу выходить в лидеры,
            <br />
            чтобы получить призы для подписчиков!
          </div>
        </div>
        <TrophyItems setSelected={setSelected} selected={selected} />
      </div>
      {LEAGUES.map((league) => league.select).map((leagueName, idx) =>
        leagueName === selected ? (
          <TrophyCard
            key={leagueName}
            item={getPrizes[idx]}
            text={
              <div>
                <img
                  className={styles[`card_image_${idx}`]}
                  src={`${urlImage}group_${idx + 1}.png`}
                />
              </div>
            }
          />
        ) : null
      )}
      <Faq />
      <Timeline
        nav={routes.default_root.default_view.leaderboard_panel.id}
        isFirst={false}
        date={dataDay}
        dataTimeline={dataTimeline}
        dataTurnir={dataTurnir}
      />
      <div className={styles.footer}>
        <div className={styles.footer_text} onClick={handleModalAbout}>
          О проекте
        </div>
        <div className={styles.footer_text} onClick={handleModalHelp}>
          Правила
        </div>
      </div>
    </div>
  );
};

export default Main;
