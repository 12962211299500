import styles from './NoTasks.module.css';

export const NoTasks = (selected: any) => {
    const weeks = [
        "",
        "12 сентября",
        "18 сентября",
        "25 сентября", 
        "2 октября", 
        "9 октября"
    ]

    return (
        <div className={styles.text}> Задания на этот период откроются {weeks[selected.selected]}.</div>
    )
}