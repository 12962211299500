import React, { useState, useEffect } from "react";
import { url, sign, authorization } from '../../utils/api/apiUrl';

function useApiData(urlPanel: URL | RequestInfo) {

	const [newData, setNewData]: any = useState([]);


	useEffect(() => {
		function fetchData() {
			fetch(urlPanel, {
				method: 'POST',
				headers: {
					'Authorization': `${authorization}`,
					'Sign': `${sign}`,
					'Content-Type': 'application/json',
				},
			})
				.then(response => (response.json()))
				.then(data => {
					setNewData(data);
				})
				.catch(error => {
					console.error('Ошибка:', error);
				});
		};
		fetchData();
	}, []);

	return (newData);
}

export default useApiData;