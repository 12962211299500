import { useState, useEffect, useContext } from "react";
import { createPortal } from "react-dom";
import { Context } from "../../context";
import { PanelHeader } from "@vkontakte/vkui";
import styles from "./ModalUpdate.module.css";
import { urlSpecialModal, isMobile, urlImage } from "../../utils/api/apiUrl";
import useApiData from "../hooks/useApiData";

interface PropsModal {
  id: number;
  title: string;
  text: string;
  status: boolean;
}

export const ModalUpdate = () => {
  const specialModal = useApiData(urlSpecialModal);
  const { setShowModal } = useContext(Context);
  const [specialModalData, setSpecialModalData] =
    useState<PropsModal>(specialModal);

  useEffect(() => {
    if (specialModal[0]) {
      setSpecialModalData(specialModal[0]);
    }
  }, [specialModal]);

  return createPortal(
    <div>
      {
      isMobile ? (
        <div className={styles.wrapper} style={{
            backgroundImage: `url(${urlImage}mobile1.png)`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'bottom center'
          }}>
          <PanelHeader
            separator={false}
            style={{ zIndex: "1000" }}
            className="panelHeader"
          >
            Community Hub Cup
          </PanelHeader>

          <div className={styles.container_m} >
            <div
              className={styles.title}
              dangerouslySetInnerHTML={{ __html: specialModalData?.title }}
            />
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: specialModalData?.text }}
            />
            <div
              className={styles.button_m}
              onClick={() => {
                setShowModal(true);
              }}
            >
              Понятно, круто!
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <div
              className={styles.title}
              dangerouslySetInnerHTML={{ __html: specialModalData?.title }}
            />
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: specialModalData?.text }}
            />
            <div
              className={styles.button}
              onClick={() => {
                setShowModal(true);
              }}
            >
              Понятно, круто!
            </div>
          </div>
        </div>
      )}
    </div>,
    document.getElementById("root")!
  );
};
