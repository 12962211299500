import React, { useState, useEffect } from 'react';
import { Panel, PanelHeader } from '@vkontakte/vkui';
import { useRouteNavigator } from '@vkontakte/vk-mini-apps-router';
import { routes } from '../../utils/routes';
import { urlImage, isMobile } from '../../utils/api/apiUrl';
import styles from './Timeline.module.css';


interface LeaderboardPanelInterface {
    nav: string;
    isFirst: boolean;
    date?: { day: number; week: number };
    dataTimeline: {
        id: number;
        week: string;
        day: string;
        subheading: string;
    }[];
    dataTurnir: { id: number; title: string; text: string }[];
}

const DataTurnirBlock: React.FC<{
    item: { title: string; text: string; id: number };
    isEven: boolean;
}> = ({ item, isEven }) => (
    <div className={isEven ? styles.desc_even : styles.desc} key={item.id}>
        <div
            dangerouslySetInnerHTML={{
                __html: item.title,
            }}
            className={styles.step_title}
        />
        <div
            dangerouslySetInnerHTML={{
                __html: item.text,
            }}
            className={styles.step_text}
        />
    </div>
);

const LastStep: React.FC<{
    item: { id: number; day: string; subheading: string };
}> = ({ item }) => (
    <div className={styles.last_step} key={item.id}>
        <img className={styles.step_cup} src={`${urlImage}cup.png`} />
        <div className={styles.footer_text}>
            <div className={styles.step_week_last}>{item.day}</div>
            <div className={styles.step_date_last}>Финал</div>
            <div
                className={styles.text_last}
                dangerouslySetInnerHTML={{
                    __html: item.subheading,
                }}
            />
        </div>
    </div>
);

const TimelineItem: React.FC<{
    item: { id: number; day: string; week: string; subheading: string };
    isEven: boolean;
    index: number;
}> = ({ isEven, item, index }) => (
    <div className={isEven ? styles.step_even : styles.step} key={item.id}>
        <div className={styles.container_date}>
            <div className={styles.step_week}>{item.week}</div>
            <div className={isEven ? styles.step_date_even : styles.step_date}>
                {item.day}
            </div>
            <div
                className={styles.text}
                dangerouslySetInnerHTML={{
                    __html: item.subheading,
                }}
            />
        </div>
        <img
            className={isEven ? styles.step_rune_even : styles.step_rune}
            src={`${urlImage}rune_${index + 2}.png`}
        />
    </div>
);

function Timeline({
    nav,
    isFirst,
    date,
    dataTimeline,
    dataTurnir,
}: LeaderboardPanelInterface) {
    const routeNavigator = useRouteNavigator();
    const [currentProgress, setCurrentProgress] = useState<number>(0);

    const TOTAL_WEEK_COUNT = dataTimeline.length;
    const TOTAL_DAYS = TOTAL_WEEK_COUNT * 7;

    useEffect(() => {
        if (date) {
            setCurrentProgress(date.week * 7 - 7 + date.day);
        }
    }, [date, dataTurnir]);

    return (
        <Panel nav={nav}>
            {isMobile && isFirst ? (
                <PanelHeader
                    separator={false}
                    style={{ zIndex: "1000" }}
                    className="panelHeader"
                >
                    Community Hub Cup
                </PanelHeader>
            ) : null}
            {isFirst ? <div className={styles.mask}></div> : null}
            <div
                className={isFirst ? styles.container_first : styles.container}
            >
                {isFirst ? (
                    <div
                        className={styles.page_title}
                        style={{ zIndex: "100" }}
                    >
                        Помоги своему сообществу победить в этой битве!{" "}
                    </div>
                ) : null}
                <div>
                    <div className={styles.container_text}>
                        {dataTurnir.map((item: any) => (
                            <DataTurnirBlock
                                key={item.id}
                                item={item}
                                isEven={item.id % 2 === 0}
                            />
                        ))}
                    </div>
                    <div className={styles.container_timeline}>
                        <div className={styles.container_progress}>
                            <div
                                className={
                                    isFirst
                                        ? styles.progress_bar
                                        : styles.progress_bar_second
                                }
                            >
                                <div
                                    className={styles.progress_current}
                                    style={{
                                        height: `${
                                            (currentProgress / TOTAL_DAYS) * 100
                                        }%`,
                                    }}
                                ></div>
                            </div>
                        </div>
                        {dataTimeline.map((item: any, index: any) => {
                            return index !== dataTimeline.length - 1 ? (
                                <TimelineItem
                                    key={item.id}
                                    isEven={item.id % 2 === 0}
                                    item={item}
                                    index={index}
                                />
                            ) : (
                                <LastStep item={item} />
                            );
                        })}
                    </div>

                    {isFirst ? (
                        <div style={{ width: "145px", margin: "0 auto" }}>
                            <button
                                className={styles.continue_button}
                                onClick={() => {
                                    routeNavigator.push(
                                        routes.default_root.default_view
                                            .hub_panel
                                    );
                                }}
                            >
                                Продолжить
                            </button>
                        </div>
                    ) : null}
                </div>
            </div>
        </Panel>
    );
}

export default React.memo(Timeline);
