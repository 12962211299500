import React, { useState, useEffect, useContext, useCallback } from "react";
import bridge from "@vkontakte/vk-bridge";
import styles from "./User.module.css";
import useApiData from "../hooks/useApiData";
import { Context } from "../../context";
import { urlImage, vkId, urlGetUser } from "../../utils/api/apiUrl";

export const User = () => {
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userPhoto, setUserPhoto] = useState("");
  const data = useApiData(`${urlGetUser}?vk_id=${vkId}`);
  const [currentProgress, setCurrentProgress] = useState(
    (data.score_fibonacci / data.max_score_fibonacci) * 100
  );
  const [shown, setShown]: any = useState();
  const { scoreCurrent }: any = useContext(Context);

  useEffect(() => {
    bridge
      .send("VKWebAppStorageGet", {
        keys: ["shown"],
      })
      .then((data) => {
        if (data.keys) {
          setShown(data.keys[0].value); // Значения получены
        }
      })
      .catch((error) => {
        // Ошибка
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (data && scoreCurrent != undefined) {
      setCurrentProgress((scoreCurrent / data.max_score_fibonacci) * 100);
    }
  }, [scoreCurrent, data]);

  useEffect(() => {
    bridge
      .send("VKWebAppGetUserInfo", {
        user_id: parseInt(vkId),
      })
      .then((data) => {
        if (data.id) {
          setUserFirstName(data.first_name);
          setUserLastName(data.last_name);
          setUserPhoto(data.photo_100);
        }
      })
      .catch((error) => {
        // Ошибка
        console.log(error);
      });
  }, []);

  function setStorageShown() {
    bridge
      .send("VKWebAppStorageSet", {
        key: "shown",
        value: "true",
      })
      .then((data) => {
        if (data.result) {
          return; // Значение переменной задано
        }
      })
      .catch((error) => {
        // Ошибка
        console.log(error);
      });
  }

  function showAlertHub(text: string) {
    setStorageShown();
    return (
      <div style={{ position: "absolute" }}>
        <div className={styles.alert_container}>
          <div className={styles.alert_text}>{text}</div>
        </div>
        <div className={styles.triangle}></div>
      </div>
    );
  }

  const recordEvent = useCallback(() => {
    ym(94652796, "reachGoal", "user");
  }, []);

  return (
    <div
      style={{
        backgroundImage: `url(${urlImage}stone.png)`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top left",
        backgroundSize: "cover",
      }}
      className={styles.container}
    >
      <div className={styles.user_container}>
        <img src={userPhoto} className={styles.photo} />
        {!shown
          ? showAlertHub("Перейди на VK Play, чтобы принять участие в турнире")
          : null}
        <div className={styles.user_name_container}>
          <div className={styles.user_name}>
            {userFirstName} {userLastName}
          </div>
          <a
            href={data.user_hub}
            className={styles.team}
            target="_blank"
            onClick={() => {
              recordEvent();
            }}
          >
            Команда {data.public_name}
          </a>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.score_container}>
          <div className={styles.value}>CP</div>
          <div className={styles.score}>{scoreCurrent}</div>
          <div className={styles.max_score}>/{data.max_score_fibonacci}</div>
        </div>
        <div className={styles.total_progress}>
          <div
            className={styles.progress}
            style={{
              width: `${currentProgress}%`,
              backgroundColor: "rgba(255, 199, 0, 1)",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};
