import React from 'react'

import styles from '../LeaderboardItem/LeaderboardItem.module.css';

interface SystemAlertsInterface {
  subtitle: any
  children?: React.ReactNode
}

function SystemAlerts({
  subtitle,
  children,
}: SystemAlertsInterface) {
  return (
    <div className={styles.popup}>
      <div className={styles.wrapper}>
        <div className={styles.alert_container}>
          <div className={styles.alert_text}>
            <div dangerouslySetInnerHTML={{ __html: subtitle }}/>
          </div>
        </div>
        <div className={styles.triangle}></div>
      </div>
      {children}
    </div>
  )
}

export default React.memo(SystemAlerts)