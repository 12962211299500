import React, { useState, useEffect } from "react";
import styles from "./ModalWinners.module.css";
import { urlFaffleWin, urlImage } from "../../utils/api/apiUrl";
import useApiData from "../hooks/useApiData";

export const ModalWinners = () => {
  const dataWin = useApiData(`${urlFaffleWin}`);
  const [dataWinners, setDataWinners] = useState(dataWin);

  useEffect(() => {
    setDataWinners(dataWin);
  }, [dataWin]);

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.img_container}>
          <img className={styles.image} src={`${urlImage}cup.png`} alt="cup" />
        </div>
        <div>
          <div
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: dataWinners.text }}
          />
        </div>
        <div className={styles.text}>
          Свяжись с{" "}
          <a className={styles.link} href={dataWinners.url} target="_blank">
            менеджером
          </a>
          <br />
          для получения новых аркан
        </div>
      </div>
    </div>
  );
};
