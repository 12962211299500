import React, { useState, useRef, createRef, useCallback } from 'react';
import styles from './LeagueItem.module.css';
import { Button } from '../Button/Button';


interface Props {
    name: any;
    erv: any;
    active_subscriptions: any;
    delta_subscriptions: any;
    total: any;
    mobile: boolean;
    setShow: any;
    url?: any;
    urlHub?: any;
    isFirst: boolean;
}

export const LeagueItem: React.FC<Props> = ({ name, erv, active_subscriptions, delta_subscriptions, total, mobile, setShow, url, urlHub, isFirst }) => {
    const [active, setActive] = useState(false);
    const divRef = useRef<HTMLDivElement>(null);
    const handleMouseEnter = () => {
        setActive(true)
    };
    const handleMouseLeave = () => {
        setActive(false)
    };

    const recordEvent = useCallback((name: string) => {
      ym(94652796, "reachGoal", name);
    }, []);
  return (
    <div
      className={isFirst ? styles.container_item_first : styles.container_item}
      key={name}
      ref={divRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => {
        setShow(<Button name={name} url={url} urlHub={urlHub} />);
      }}
    >
      <div className={styles.item_name}>{name}</div>
      {active && !mobile && (
        <div className={styles.container_buttons}>
          <a
            href={`${url}`}
            className={styles.button_sub}
            target="_blank"
            onClick={() => {
              recordEvent("sub");
            }}
          >
            Подписаться
          </a>
          <a
            href={`${urlHub}`}
            className={styles.button_hub}
            target="_blank"
            onClick={() => {
              recordEvent("hub");
            }}
          >
            Перейти в хаб
          </a>
        </div>
      )}
      <div className={styles.item_inner}>
        <div className={styles.total}>{total}</div>
        <div className={styles.item_erv}>{erv}</div>
        <div className={styles.item_sub}>{active_subscriptions}</div>
        <div className={styles.item_sub_delta}>{delta_subscriptions}</div>
      </div>
    </div>
  );
};
