import React, { useState, useEffect } from 'react';
import { Panel } from '@vkontakte/vkui';
import styles from './ErrorView.module.css';
import { urlImage } from '../utils/api/apiUrl';
import { useRouteNavigator } from '@vkontakte/vk-mini-apps-router';
import { routes } from '../utils/routes';

export const ErrorView = (nav: any) => {
    const routeNavigator = useRouteNavigator();
    // useEffect(() => {
    //     routeNavigator.push(routes.default_root.error_view.error);
    // }, [])

    function restartApp() {
        routeNavigator.push(routes.default_root.default_view.home_panel);
    }
    return (
        <Panel nav={nav}>
            <div style={{
                backgroundImage: `url(${urlImage}dota1.png)`,
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                height: '100vh'
            }}>
                <div className={styles.container}>
                    <div className={styles.card}>
                        <div className={styles.image_container}>
                            <img src={`${urlImage}ping.png`} alt="" className={styles.image} />
                        </div>
                        <div className={styles.title}>Упс... кажется, произошла какая-то ошибка :(</div>
                        <div className={styles.description}>Пожалуйста, перезагрузите приложение</div>
                        <div className={styles.button_container}>
                            <button type='button' onClick={() => {
                                restartApp()
                            }} className={styles.button}>Перезагрузить</button>
                        </div>
                    </div>
                </div>
            </div>
        </Panel>
    )
}