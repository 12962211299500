import React from "react";
import styles from "./Faq.module.css";
import { Question } from "../Question/Question";
import useApiData from "../hooks/useApiData";
import { urlFaq, urlQuestion } from "../../utils/api/apiUrl";

export const Faq: React.FC = () => {
    const getQuestion = useApiData(urlQuestion);
    const dataFaq = useApiData(urlFaq);

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>Вопросы и правила</h2>
            <div className={styles.items_container}>
                {dataFaq?.map((item: { title: string; text: string }) => (
                    <div key={item.title} className={styles.item_container}>
                        <Question title={item.title} text={item.text} />
                    </div>
                ))}
            </div>
            <a
                href={getQuestion.question}
                className={styles.button_link}
                target="_blank"
            >
                Задать вопрос
            </a>
        </div>
    );
};
