import React, { useEffect, useState } from "react";
import styles from "./Week.module.css";
import classNames from "classnames";

interface Props {
  currentWeek: number;
  setSelected: any;
  selected: number;
  weeks: number;
}

export const Week: React.FC<Props> = ({
  currentWeek,
  setSelected,
  selected,
  weeks,
}) => {
  const totalWeek = weeks - 1;
  const [isLeft, setIsLeft] = useState(false);
  const [isRight, setIsRight] = useState(false);

  useEffect(() => {
    if (isLeft) {
      setTimeout(() => {
        setIsLeft(false);
      }, 900);
    }
  }, [isLeft]);

  useEffect(() => {
    if (isRight) {
      setTimeout(() => {
        setIsRight(false);
      }, 900);
    }
  }, [isRight]);

  let arrayWeek = [];
  for (let i = 1; i <= totalWeek; i++) {
    arrayWeek.push(
      <div className={styles.container_week} key={i}>
        {selected === i ? (
          <div
            className={styles.current}
            onClick={() => {
              setSelected(i);
            }}
          >
            Неделя {i}
          </div>
        ) : null}
        {selected < i ? (
          <div
            className={styles.after}
            onClick={() => {
              setIsRight(true);
              setSelected(i);
            }}
          >
            Неделя {i}
          </div>
        ) : null}
        {selected > i ? (
          <div
            className={styles.before}
            onClick={() => {
              setIsLeft(true);
              setSelected(i);
            }}
          >
            Неделя {i}
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <div
      className={classNames(
        styles.container,
        isLeft ? styles.container_left : null,
        isRight ? styles.container_right : null
      )}
    >
      <div
        className={classNames(
          styles.container_inner
          // , isLeft ? styles.container_left : null, isRight ? styles.container_right : null
        )}
      >
        <div className={styles.container_week}>
          <div className={styles.clean}>Неделя 6</div>{" "}
        </div>
        {arrayWeek}
        <div className={styles.container_week}>
          <div className={styles.clean}>Неделя 6</div>{" "}
        </div>
      </div>
    </div>
  );
};
