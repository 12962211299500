import React from "react";
import bridge from "@vkontakte/vk-bridge";
import styles from "./LeagueHeader.module.css";
import { urlImage } from "../../utils/api/apiUrl";
import { League } from "../League/League";

interface Props {
  setSelected: React.Dispatch<React.SetStateAction<number>>;
  title: any;
  dataLeaderboard: {
    name: string;
    erv: string;
    rating: string;
    active_subscriptions: number;
    delta_subscriptions: number;
    url_public: string;
    url_hub: string;
  }[];
  before: number;
  after: number;
  number: string;
}
export const LeagueHeader: React.FC<Props> = ({
  setSelected,
  title,
  dataLeaderboard,
  before,
  after,
  number,
}) => {
  function setStorageShownAnimation() {
    bridge
      .send("VKWebAppStorageSet", {
        key: "shownAnimation",
        value: "true",
      })
      .then((data) => {
        if (data.result) {
          return; // Значение переменной задано
        }
      })
      .catch((error) => {
        // Ошибка
        console.log(error);
      });
  }


  return (
    <div>
      <div className={styles.container_header}>
        <div
          style={{
            backgroundImage: `url(${urlImage}vector.svg)`,
            backgroundSize: "10px 20px",
            width: "20px",
            height: "20px",
            backgroundRepeat: "no-repeat",
            rotate: "180deg",
            cursor: "pointer",
          }}
          onClick={() => {
            setSelected(before);
          }}
        ></div>
        <div className={styles.header_title}>{title}</div>
        <div
          style={{
            backgroundImage: `url(${urlImage}vector.svg)`,
            backgroundSize: "10px 20px",
            width: "20px",
            height: "20px",
            backgroundRepeat: "no-repeat",
            cursor: "pointer",
          }}
          onClick={() => {
            setSelected(after);
            setStorageShownAnimation();
          }}
        ></div>
      </div>
      <League dataLeaderboard={dataLeaderboard} number={number} />
    </div>
  );
};
