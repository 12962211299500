import { useState } from "react";
import { urlCheckSpecial, authorization, sign } from "../../utils/api/apiUrl";

const useCheckSpecial = () => {
    const [special, setSpecial]: any = useState(false);
    const [enough, setEnough] = useState(false);

    async function checkSpecial(id: any) {
        await fetch(`${urlCheckSpecial}${id}`, {
            method: "POST",
            headers: {
                Authorization: `${authorization}`,
                Sign: `${sign}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setSpecial(data.result);
                if (data.result) {
                    setSpecial(true);
                    setEnough(true);
                }
            })
            .catch((error) => {
                console.error("Ошибка:", error);
            });
    }

    return { special, enough, checkSpecial };
};

export default useCheckSpecial;
