import React from "react";
import styles from "./TrophyItems.module.css";
import cn from "classnames";
import { LEAGUES } from "../../utils/const";

interface Props {
    setSelected: React.Dispatch<React.SetStateAction<number>>;
    selected: number;
}

export const TrophyItems: React.FC<Props> = ({ setSelected, selected }) => (
    <div className={styles.container_main}>
        {LEAGUES.map((item) => (
            <div
                key={item.id}
                id={item.id}
                aria-controls={item.aria}
                className={cn(
                    selected === item.select
                        ? styles.container_active
                        : styles.container
                )}
                onClick={() => {
                    setSelected(item.select);
                }}
            >
                {item.title}
            </div>
        ))}
    </div>
);
