import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { urlImage, pathToSite } from "../../utils/api/apiUrl";
import styles from "./prize.module.css";

interface Props {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  mobile: boolean;
  winners: string;
  participants: string;
  url_manager: string;
  name_manager: string;
}
function ModalPrize({
  setShow,
  mobile,
  winners,
  participants,
  url_manager,
  name_manager,
}: Props) {
  return createPortal(
    <div>
      {!mobile && (
        <div className={styles.modal_background}>
          <div className={styles.modal_container}>
            <div className={styles.wrapper_info}>
              <div className={styles.modal_title}>{winners}</div>
              <div className={styles.list}>
                <div
                  className={styles.modal_label}
                  dangerouslySetInnerHTML={{ __html: participants }}
                />
              </div>
              <div className={styles.modal_label}>
                В ближайшее время с вами свяжется менеджер{" "}
                <a
                  className={styles.button}
                  href={url_manager}
                  target="_blank"
                  dangerouslySetInnerHTML={{ __html: name_manager }}
                />
              </div>
            </div>

            <div className={styles.container_second}>
              <img
                className={styles.wrapper_close}
                src={`${urlImage}close.svg`}
                onClick={() => {
                  setShow(false);
                }}
              />
              <img className={styles.image_game} src={`${urlImage}zevs.png`} />
            </div>
          </div>
        </div>
      )}
      {mobile && (
        <div
          className={styles.container}
          style={{
            backgroundImage: `url(${urlImage}mobile1.png)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "bottom center",
          }}
        >
          <div className={styles.wrapper_info}>
            <div className={styles.modal_title}>{winners}</div>
            <div className={styles.list}>
              <div
                className={styles.modal_label}
                dangerouslySetInnerHTML={{ __html: participants }}
              />
            </div>
            <div className={styles.modal_label}>
              В ближайшее время с вами свяжется менеджер{" "}
              <a
                className={styles.button}
                href={url_manager}
                target="_blank"
                dangerouslySetInnerHTML={{ __html: name_manager }}
              />
            </div>{" "}
          </div>
          <div className={styles.container_second}>
            <img
              className={styles.wrapper_close}
              src={`${urlImage}close.svg`}
              onClick={() => {
                setShow(false);
              }}
            />
            <img className={styles.image_game} src={`${urlImage}zevs.png`} />
          </div>
        </div>
      )}
    </div>,
    document.getElementById("root")!
  );
}

export default React.memo(ModalPrize);
