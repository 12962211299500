import React from "react";
import { createPortal } from "react-dom";
import { PanelHeader } from "@vkontakte/vkui";
import styles from "./modals.module.css";
import { urlImage } from "../../utils/api/apiUrl";

interface ModuleInterface {
    handleClick: () => void;
    title: string;
    subtitle: string | null;
    mobile: boolean;
}

function ModalPage({ handleClick, title, subtitle, mobile }: ModuleInterface) {
    return createPortal(
        <div>
            {mobile ? (
                <div>
                    <div
                        className={styles.container}
                        style={{
                            backgroundImage: `url(${urlImage}mobile1.png)`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "bottom center",
                        }}
                    >
                        <PanelHeader
                            separator={false}
                            fixed={true}
                            style={{ zIndex: "1000" }}
                        >
                            Community Hub Cup
                        </PanelHeader>
                        <div className={styles.title}>
                            {title}
                            <img
                                className={styles.image}
                                src={`${urlImage}close.svg`}
                                onClick={handleClick}
                            />
                        </div>
                        <div className={styles.paragraph}>
                            {subtitle ? (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: subtitle,
                                    }}
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
            ) : (
                <div className={styles.modal_background}>
                    <div className={styles.modal_container}>
                        <div className={styles.modal_title}>
                            {title}
                            <img
                                className={styles.wrapper_image}
                                src={`${urlImage}close.svg`}
                                onClick={handleClick}
                            />
                        </div>
                        <div>
                            {subtitle ? (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: subtitle,
                                    }}
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
            )}
        </div>,
        document.getElementById("root")!
    );
}

export default React.memo(ModalPage);
