import React, { createContext, useEffect, useState } from "react";
import useApiData from "./components/hooks/useApiData";
import { urlGetUser, vkId } from "./utils/api/apiUrl";

export type ContextType = {
  scoreCurrent?: number;
  setScoreCurrent?: (s: number) => void;
  dataUser: {
    score_fibonacci: number;
    public_id: string;
    public_name: string;
    max_score_fibonacci: number;
    special_tasks: [];
    user_hub: string;
  };
  setDataUser: (s: {}) => void;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Context = createContext<ContextType>({
  dataUser: {
    score_fibonacci: 0,
    public_id: "",
    public_name: "",
    max_score_fibonacci: 0,
    special_tasks: [],
    user_hub: "",
  },
  setDataUser: function (s: {}): void {
    throw new Error("Function not implemented.");
  },
  showModal: false,
  setShowModal: function (s: {}): void {
    throw new Error("Function not implemented.");
  },
});

export default function ContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [scoreCurrent, setScoreCurrent] = useState<number>(0);
  const dataUserApi = useApiData(`${urlGetUser}?vk_id=${vkId}`);
  const [dataUser, setDataUser] = useState(dataUserApi);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setDataUser(dataUserApi);
  }, [dataUserApi]);

  return (
    <Context.Provider
      value={{
        scoreCurrent,
        setScoreCurrent,
        dataUser,
        setDataUser,
        showModal,
        setShowModal,
      }}
    >
      {children}
    </Context.Provider>
  );
}
