import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import bridge from "@vkontakte/vk-bridge";
import { RouterProvider } from '@vkontakte/vk-mini-apps-router';
import { AdaptivityProvider, AppRoot, ConfigProvider } from '@vkontakte/vkui';
import { router } from "./utils/routes";
import App from "./App";
import ContextProvider from "./context";



// Init VK  Mini App
bridge.send("VKWebAppInit");

function MainPage() {

  return (
    <ConfigProvider>
      <AdaptivityProvider>
        <AppRoot>
          <RouterProvider router={router} >
            <ContextProvider>
              <App />
            </ContextProvider>
          </RouterProvider>
        </AppRoot>
      </AdaptivityProvider>
    </ConfigProvider>
  )
}


ReactDOM.render(
  <MainPage />
  , document.getElementById("root"));

if (process.env.NODE_ENV === "development") {
  import("./eruda").then(({ default: eruda }) => { }); //runtime download
}
