import React from "react";
import styles from "./TrophyCard.module.css";

interface Props {
    item: {
        text: string;
        first: string;
        second: string;
        third: string;
        additional_text: string;
    };
    text: React.ReactElement;
}

export const TrophyCard: React.FC<Props> = ({ text, item }) => (
    <div className={styles.container}>
        <div className={styles.card}>
            <div className={styles.card_content}>{text}</div>
            <div className={styles.container_list}>
                <div className={styles.card_title}>{item.text}</div>
                <div className={styles.list}>
                    <div className={styles.list_item}>
                        <div className={styles.item_title}>1 место</div>
                        <div className={styles.item_description}>
                            {item.first}
                        </div>
                    </div>
                    <div className={styles.list_item}>
                        <div className={styles.item_title}>2 место</div>
                        <div className={styles.item_description}>
                            {item.second}
                        </div>
                    </div>
                    <div className={styles.list_item}>
                        <div className={styles.item_title}>3 место</div>
                        <div className={styles.item_description}>
                            {item.third}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={styles.description}>{item.additional_text}</div>
    </div>
);
