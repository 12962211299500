import React, { useState, useEffect, useContext } from "react";
import { Panel, PanelHeader } from "@vkontakte/vkui";
import "./Home.module.css";
import TabsHeader from "../../components/TabsHeader/TabsHeader";
import Main from "../../components/tabsHome/Main/Main";
import Tasks from "../../components/tabsHome/Tasks/Tasks";
import Leaderboard from "../../components/tabsHome/Leaderboard/Leaderboard";
import { User } from "../../components/User/User";
import {
    urlImage,
    urlAbout,
    urlPrizes,
    urlGetUser,
    vkId,
    authorization,
    sign,
    isMobile,
} from "../../utils/api/apiUrl";
import useApiData from "../../components/hooks/useApiData";
import { Context } from "../../context";
import { HEADERS } from "../../utils/const";

interface Props {
    nav: string;
    selected: string;
    setSelected: React.Dispatch<React.SetStateAction<string>>;

    dataDay: { day: number; week: number };
    dataTimeline: {
        id: number;
        week: string;
        day: string;
        subheading: string;
    }[];
    dataTurnir: { id: number; title: string; text: string }[];
}

const Home: React.FC<Props> = ({
    nav,
    selected,
    setSelected,
    dataDay,
    dataTimeline,
    dataTurnir,
}) => {
    const [currentWeek, setCurrentWeek] = useState<number>(2);
    const [currentDay, setCurrentDay] = useState<number>(2);
    const [about, setAbout] = useState<{ html: string }>({ html: "" });

    const dataAbout = useApiData(urlAbout);
    const getPrizes = useApiData(urlPrizes);

    const { scoreCurrent, setScoreCurrent }: any = useContext(Context);

    useEffect(() => {
        if (dataAbout) {
            setAbout(dataAbout);
        }
    }, [dataAbout]);

    function getDate(data: { day: number; week: number }) {
        setCurrentDay(data.day);
        setCurrentWeek(data.week);
    }

    function checkScore() {
        fetch(`${urlGetUser}?vk_id=${vkId}`, {
            method: "POST",
            headers: {
                Authorization: `${authorization}`,
                Sign: `${sign}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (setScoreCurrent) {
                    setScoreCurrent(data.score_fibonacci);
                }
            })
            .catch((error) => {
                console.error("Ошибка:", error);
            });
    }

    useEffect(() => {
        checkScore();
    }, [scoreCurrent]);

    useEffect(() => {
        getDate(dataDay);
    }, [dataDay]);

    return (
        <Panel nav={nav}>
            {isMobile ? (
                <PanelHeader
                    separator={false}
                    style={{ zIndex: "1000" }}
                    className="panelHeader"
                >
                    Community Hub Cup
                </PanelHeader>
            ) : null}
            <div
                style={{
                    backgroundImage: `url(${urlImage}app.png)`,
                    backgroundSize: "cover",
                    backgroundPosition: "bottom center",
                    minHeight: "90vh",
                    position: "relative",
                    overflow: "hidden",
                }}
            >
                <TabsHeader selected={selected} setSelected={setSelected} />
                {selected === HEADERS[0].select ? (
                    <Main
                        dataDay={dataDay}
                        dataAbout={about}
                        getPrizes={getPrizes}
                        dataTimeline={dataTimeline}
                        dataTurnir={dataTurnir}
                    />
                ) : null}
                {selected === HEADERS[1].select ? (
                    <>
                        <Tasks
                            currentDay={currentDay}
                            currentWeek={currentWeek}
                            weeks={dataTurnir.length}
                        />
                        <User />
                    </>
                ) : null}
                {selected === HEADERS[2].select ? (
                    <>
                        <Leaderboard />
                        <User />
                    </>
                ) : null}
            </div>
        </Panel>
    );
};

export default Home;
