import React, { useState, useEffect } from "react";
import styles from "./Day.module.css";
import { SystemDisDays } from "../SystemDisDays/SystemDisDays";
import activeCurrent from "./svg/activeCurrent.svg";
import sevenDisactive from "./svg/sevenDisactive.svg";
import sevenCurrentActive from "./svg/sevenCurrentActive.svg";
import afterDisactive from "./svg/afterDisactive.svg";
import currentDisactive from "./svg/currentDisactive.svg";
import active from "./svg/active.svg";
import activeSeven from "./svg/activeSeven.svg";
import sevenNotCurrent from "./svg/sevenNotCurrent.svg";

interface Props {
  day: number;
  currentDay: any;
  setActiveDayCurrentWeek: any;
  activeDayCurrentWeek: any;
}

export const Day: React.FC<Props> = ({
  day,
  currentDay,
  setActiveDayCurrentWeek,
  activeDayCurrentWeek,
}) => {
  const [systemActive, setSystemActive] = useState(false);
  useEffect(() => {
    if (systemActive) {
      setTimeout(setSystemActive, 1500, false);
    }
  }, [systemActive]);

  return (
    <div className={styles.container}>
      {currentDay == day &&
        currentDay != 7 &&
        activeDayCurrentWeek == currentDay && ( //active current
          <div className={styles.active}>
            <div className={styles.svg_active}>
              <img src={activeCurrent} alt="active day" />
            </div>
            <div className={styles.day_active}>{day}</div>
          </div>
        )}

      {day === 7 &&
        7 != currentDay &&
        activeDayCurrentWeek != day && ( //disactive 7 not current
          <div
            className={styles.container_last}
            onClick={() => {
              setActiveDayCurrentWeek(day);
              setSystemActive(true);
            }}
          >
            <div className={styles.svg_last}>
              <img src={sevenDisactive} alt="last disactive day" />
            </div>
            <div className={styles.day_last}>{day}</div>
          </div>
        )}
      {currentDay === 7 &&
        day === 7 &&
        activeDayCurrentWeek == day && ( //active 7 current
          <div className={styles.container_last_active}>
            <div className={styles.svg_last_active}>
              <img src={sevenCurrentActive} alt="active day last" />
            </div>
            <div className={styles.day_last_active}>{day}</div>
          </div>
        )}
      {currentDay < day &&
        day !== 7 &&
        activeDayCurrentWeek != day && ( //after disactive
          <div
            className={styles.container_in}
            onClick={() => {
              setActiveDayCurrentWeek(day);
              setSystemActive(true);
            }}
          >
            <div className={styles.svg_after}>
              <img src={afterDisactive} alt="after day" />
            </div>
            <div className={styles.day}>{day}</div>
          </div>
        )}
      {currentDay == day &&
        activeDayCurrentWeek != day &&
        day !== 7 && ( //current disactive
          <div
            className={styles.before}
            onClick={() => {
              setActiveDayCurrentWeek(day);
            }}
          >
            <div className={styles.svg_before}>
              <img src={currentDisactive} alt="current day" />
            </div>
            <div className={styles.day_current_dis}>{day}</div>
          </div>
        )}

      {currentDay > day &&
        activeDayCurrentWeek != day &&
        day !== 7 && ( //before disactive
          <div
            onClick={() => {
              setActiveDayCurrentWeek(day);
            }}
            className={styles.container_before_dis}
          >
            <img src={afterDisactive} alt="after day" />
            <div className={styles.day_before}>{day}</div>
          </div>
        )}
      {currentDay != day &&
        day !== 7 &&
        activeDayCurrentWeek == day &&
        day > currentDay && ( //active not 7 not current
          <div
            onClick={() => {
              setActiveDayCurrentWeek(day);
            }}
            className={styles.active_default}
          >
            {systemActive ? (
              <div className={styles.alert_container}>
                <SystemDisDays
                  subtitle="Это задание еще недоступно"
                  img={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                    >
                      <path
                        d="M24.2993 15.8122C24.1118 15.6247 23.8575 15.5194 23.5923 15.5194C23.3271 15.5194 23.0728 15.6247 22.8853 15.8122L20.0923 18.6052L17.2993 15.8122C17.1107 15.63 16.8581 15.5292 16.5959 15.5315C16.3337 15.5338 16.0829 15.6389 15.8975 15.8244C15.7121 16.0098 15.6069 16.2606 15.6046 16.5228C15.6024 16.785 15.7032 17.0376 15.8853 17.2262L18.6783 20.0192L15.8853 22.8122C15.7898 22.9044 15.7136 23.0148 15.6612 23.1368C15.6088 23.2588 15.5812 23.39 15.5801 23.5228C15.5789 23.6555 15.6042 23.7872 15.6545 23.9101C15.7048 24.033 15.779 24.1447 15.8729 24.2386C15.9668 24.3324 16.0785 24.4067 16.2014 24.457C16.3243 24.5073 16.4559 24.5326 16.5887 24.5314C16.7215 24.5303 16.8527 24.5027 16.9747 24.4503C17.0967 24.3979 17.2071 24.3217 17.2993 24.2262L20.0923 21.4332L22.8853 24.2262C23.0739 24.4083 23.3265 24.5091 23.5887 24.5068C23.8509 24.5046 24.1017 24.3994 24.2871 24.214C24.4725 24.0286 24.5777 23.7778 24.58 23.5156C24.5823 23.2534 24.4815 23.0008 24.2993 22.8122L21.5063 20.0192L24.2993 17.2262C24.4868 17.0386 24.5921 16.7843 24.5921 16.5192C24.5921 16.254 24.4868 15.9997 24.2993 15.8122Z"
                        fill="#FFC700"
                      />
                      <path
                        d="M12.5923 22.5186C10.6145 22.5186 8.68108 21.9321 7.03659 20.8333C5.3921 19.7344 4.11037 18.1727 3.3535 16.3454C2.59662 14.5181 2.39859 12.5075 2.78444 10.5677C3.17029 8.62785 4.1227 6.84602 5.52122 5.44749C6.91975 4.04897 8.70158 3.09656 10.6414 2.71071C12.5812 2.32486 14.5919 2.52289 16.4191 3.27976C18.2464 4.03664 19.8082 5.31837 20.907 6.96286C22.0058 8.60735 22.5923 10.5407 22.5923 12.5186C22.5923 12.7838 22.6976 13.0381 22.8852 13.2257C23.0727 13.4132 23.3271 13.5186 23.5923 13.5186C23.8575 13.5186 24.1119 13.4132 24.2994 13.2257C24.4869 13.0381 24.5923 12.7838 24.5923 12.5186C24.5923 10.1452 23.8885 7.82511 22.5699 5.85172C21.2513 3.87833 19.3772 2.34026 17.1845 1.43201C14.9918 0.523754 12.579 0.286114 10.2512 0.749137C7.92344 1.21216 5.78524 2.35505 4.10701 4.03328C2.42878 5.71151 1.28589 7.8497 0.822867 10.1775C0.359845 12.5052 0.597485 14.918 1.50574 17.1108C2.41399 19.3035 3.95206 21.1776 5.92545 22.4962C7.89884 23.8148 10.2189 24.5186 12.5923 24.5186C12.8575 24.5186 13.1119 24.4132 13.2994 24.2257C13.4869 24.0381 13.5923 23.7838 13.5923 23.5186C13.5923 23.2533 13.4869 22.999 13.2994 22.8115C13.1119 22.6239 12.8575 22.5186 12.5923 22.5186Z"
                        fill="#FFC700"
                      />
                      <path
                        d="M12.5925 6.51856C12.3273 6.51856 12.0729 6.62392 11.8854 6.81145C11.6978 6.99899 11.5925 7.25334 11.5925 7.51856V12.1046L8.88549 14.8115C8.78998 14.9038 8.7138 15.0141 8.66139 15.1361C8.60898 15.2581 8.5814 15.3894 8.58024 15.5221C8.57909 15.6549 8.60439 15.7866 8.65467 15.9095C8.70495 16.0324 8.77921 16.144 8.8731 16.2379C8.96699 16.3318 9.07864 16.4061 9.20154 16.4564C9.32443 16.5066 9.45611 16.532 9.58889 16.5308C9.72167 16.5296 9.85289 16.5021 9.97489 16.4496C10.0969 16.3972 10.2072 16.3211 10.2995 16.2255L13.2995 13.2256C13.487 13.0381 13.5924 12.7837 13.5925 12.5186V7.51856C13.5925 7.25334 13.4871 6.99899 13.2996 6.81145C13.1121 6.62392 12.8577 6.51856 12.5925 6.51856Z"
                        fill="#FFC700"
                      />
                    </svg>
                  }
                />
              </div>
            ) : null}
            <img src={active} alt="active day" />

            <div className={styles.active_default_day}>{day}</div>
          </div>
        )}
      {currentDay != day &&
        day !== 7 &&
        activeDayCurrentWeek == day &&
        day < currentDay && ( //active not 7 not current before
          <div
            onClick={() => {
              setActiveDayCurrentWeek(day);
            }}
            className={styles.active_default_before}
          >
            <img src={active} alt="active day" />

            <div className={styles.active_default_day_before}>{day}</div>
          </div>
        )}

      {currentDay != day &&
        day === 7 &&
        activeDayCurrentWeek == day && ( //active 7 not current
          <div className={styles.active_last}>
            {systemActive ? (
              <div className={styles.alert_container}>
                <SystemDisDays
                  subtitle="Это задание еще недоступно"
                  img={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                    >
                      <path
                        d="M24.2993 15.8122C24.1118 15.6247 23.8575 15.5194 23.5923 15.5194C23.3271 15.5194 23.0728 15.6247 22.8853 15.8122L20.0923 18.6052L17.2993 15.8122C17.1107 15.63 16.8581 15.5292 16.5959 15.5315C16.3337 15.5338 16.0829 15.6389 15.8975 15.8244C15.7121 16.0098 15.6069 16.2606 15.6046 16.5228C15.6024 16.785 15.7032 17.0376 15.8853 17.2262L18.6783 20.0192L15.8853 22.8122C15.7898 22.9044 15.7136 23.0148 15.6612 23.1368C15.6088 23.2588 15.5812 23.39 15.5801 23.5228C15.5789 23.6555 15.6042 23.7872 15.6545 23.9101C15.7048 24.033 15.779 24.1447 15.8729 24.2386C15.9668 24.3324 16.0785 24.4067 16.2014 24.457C16.3243 24.5073 16.4559 24.5326 16.5887 24.5314C16.7215 24.5303 16.8527 24.5027 16.9747 24.4503C17.0967 24.3979 17.2071 24.3217 17.2993 24.2262L20.0923 21.4332L22.8853 24.2262C23.0739 24.4083 23.3265 24.5091 23.5887 24.5068C23.8509 24.5046 24.1017 24.3994 24.2871 24.214C24.4725 24.0286 24.5777 23.7778 24.58 23.5156C24.5823 23.2534 24.4815 23.0008 24.2993 22.8122L21.5063 20.0192L24.2993 17.2262C24.4868 17.0386 24.5921 16.7843 24.5921 16.5192C24.5921 16.254 24.4868 15.9997 24.2993 15.8122Z"
                        fill="#FFC700"
                      />
                      <path
                        d="M12.5923 22.5186C10.6145 22.5186 8.68108 21.9321 7.03659 20.8333C5.3921 19.7344 4.11037 18.1727 3.3535 16.3454C2.59662 14.5181 2.39859 12.5075 2.78444 10.5677C3.17029 8.62785 4.1227 6.84602 5.52122 5.44749C6.91975 4.04897 8.70158 3.09656 10.6414 2.71071C12.5812 2.32486 14.5919 2.52289 16.4191 3.27976C18.2464 4.03664 19.8082 5.31837 20.907 6.96286C22.0058 8.60735 22.5923 10.5407 22.5923 12.5186C22.5923 12.7838 22.6976 13.0381 22.8852 13.2257C23.0727 13.4132 23.3271 13.5186 23.5923 13.5186C23.8575 13.5186 24.1119 13.4132 24.2994 13.2257C24.4869 13.0381 24.5923 12.7838 24.5923 12.5186C24.5923 10.1452 23.8885 7.82511 22.5699 5.85172C21.2513 3.87833 19.3772 2.34026 17.1845 1.43201C14.9918 0.523754 12.579 0.286114 10.2512 0.749137C7.92344 1.21216 5.78524 2.35505 4.10701 4.03328C2.42878 5.71151 1.28589 7.8497 0.822867 10.1775C0.359845 12.5052 0.597485 14.918 1.50574 17.1108C2.41399 19.3035 3.95206 21.1776 5.92545 22.4962C7.89884 23.8148 10.2189 24.5186 12.5923 24.5186C12.8575 24.5186 13.1119 24.4132 13.2994 24.2257C13.4869 24.0381 13.5923 23.7838 13.5923 23.5186C13.5923 23.2533 13.4869 22.999 13.2994 22.8115C13.1119 22.6239 12.8575 22.5186 12.5923 22.5186Z"
                        fill="#FFC700"
                      />
                      <path
                        d="M12.5925 6.51856C12.3273 6.51856 12.0729 6.62392 11.8854 6.81145C11.6978 6.99899 11.5925 7.25334 11.5925 7.51856V12.1046L8.88549 14.8115C8.78998 14.9038 8.7138 15.0141 8.66139 15.1361C8.60898 15.2581 8.5814 15.3894 8.58024 15.5221C8.57909 15.6549 8.60439 15.7866 8.65467 15.9095C8.70495 16.0324 8.77921 16.144 8.8731 16.2379C8.96699 16.3318 9.07864 16.4061 9.20154 16.4564C9.32443 16.5066 9.45611 16.532 9.58889 16.5308C9.72167 16.5296 9.85289 16.5021 9.97489 16.4496C10.0969 16.3972 10.2072 16.3211 10.2995 16.2255L13.2995 13.2256C13.487 13.0381 13.5924 12.7837 13.5925 12.5186V7.51856C13.5925 7.25334 13.4871 6.99899 13.2996 6.81145C13.1121 6.62392 12.8577 6.51856 12.5925 6.51856Z"
                        fill="#FFC700"
                      />
                    </svg>
                  }
                />
              </div>
            ) : null}
              <img src={sevenNotCurrent} alt="active day" />
            <div className={styles.active_last_day}>{day}</div>
          </div>
        )}

      {currentDay == day &&
        day === 7 &&
        activeDayCurrentWeek != day && ( //7 disactive current
          <div
            onClick={() => {
              setActiveDayCurrentWeek(day);
            }}
            className={styles.last_current_dis}
          >
            <img src={activeSeven} alt="active day" />
            <div className={styles.last_current_dis_day}>{day}</div>
          </div>
        )}
    </div>
  );
};
