import React, { ReactElement } from 'react';
import { urlImage } from '../../utils/api/apiUrl';
import AlertLeaderBoard from '../AlertLeaderBoard/AlertLeaderBoard';
import styles from './LeaderboardItem.module.css';


interface Props {
    text: string;
    title: string;
    img: ReactElement;
    mobile: boolean;
}

export const LeaderboardItem: React.FC<Props> = ({ text, title, img, mobile }) => {
    return (
        <div className={styles.item}>
            {mobile &&

                <AlertLeaderBoard subtitle={text}>
                    <div className={styles.mobile_item}>
                        {img}
                        <div className={styles.icon_q}
                            style={{
                                backgroundImage: `url(${urlImage}q.svg)`,
                                backgroundSize: '7px 13px',
                                backgroundRepeat: 'no-repeat',
                                width: '7px',
                                height: '13px'
                            }}
                        ></div>
                    </div>
                </AlertLeaderBoard>
            }
            {!mobile &&
                <AlertLeaderBoard subtitle={text}>
                    <div className={styles.container_alert}>
                        <div className={styles.title}>{title}</div>
                        <div className={styles.icon_q}
                            style={{
                                backgroundImage: `url(${urlImage}q.svg)`,
                                backgroundSize: '7px 12px',
                                backgroundRepeat: 'no-repeat',
                                width: '7px',
                                height: '12px'
                            }}
                        ></div>
                    </div></AlertLeaderBoard>
            }

        </div>
    )
}

