import React from "react";
import { HeaderItem } from "../../components/HeaderItem/HeaderItem";
import styles from "./TabsHeader.module.css";
import { HEADERS } from "../../utils/const";

interface Props {
    selected: string;
    setSelected: React.Dispatch<React.SetStateAction<string>>;
}

const TabsHeader: React.FC<Props> = ({ selected, setSelected }) => (
    <div className={styles.container}>
        {HEADERS.map((item: any) => (
            <HeaderItem
                title={item.title}
                id={item.id}
                aria={item.aria}
                select={item.select}
                setSelected={setSelected}
                selected={selected}
                key={item.id}
            />
        ))}
    </div>
);

export default TabsHeader;
