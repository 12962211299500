import React, { useEffect, useRef, useState } from "react";
import styles from "./ModalTask.module.css";
import bridge from "@vkontakte/vk-bridge";
import { createPortal } from "react-dom";
import { urlImage, vkId } from "../../utils/api/apiUrl";

interface Props {
  mobile?: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  link?: any;
  number: number;
}

interface TasksDataProps {
  8: {
    title: string;
    paragraph: string;
  };
  9: {
    title: string;
    paragraph: string;
  };
  13: {
    title: string;
    paragraph: string;
    copy: string;
  };
}

const tasksData: TasksDataProps = {
  8: {
    title: "Пригласи друга <br /> в Миниапп",
    paragraph:
      "Скопируй ссылку для своего друга, чтобы помогать вашему сообществу вместе!",
  },
  9: {
    title: "Пригласить друга <br /> в сообщество!",
    paragraph:
      "Скопируй ссылку для своего друга, чтобы помогать вашему сообществу вместе!",
  },
  13: {
    title: "Скопируй текст <br /> и опубликуй на своей стене:",
    paragraph: "Я участвую в #CommunityHubCup! Присоединяйся!",
    copy: `Я участвую в #CommunityHubCup! Присоединяйся!`,
  },
};

function ModalTask({ mobile, setShow, link, number }: Props) {
  const linkToCopy = `https://vk.com/app51712433#%2F&ref=${vkId}`;
  const [success, setSuccess]: any = useState(false);

  const copyLink = (link: any) => {
    bridge
      .send("VKWebAppCopyText", {
        text: link,
      })
      .then((data) => {
        if (data.result) {
          setSuccess(<div className={styles.hint}>Скопировано!</div>);
        } else {
          setSuccess(<div className={styles.hint}>Попробуйте ещё раз.</div>);
        }
      })
      .catch((error) => {
        // Ошибка
        console.log(error);
      });
  };

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false);
      }, 1500);
    }
  }, [success]);

  return createPortal(
    <div>
      {!mobile ? (
        <div className={styles.modal_background}>
          <div className={styles.container}>
            <div>
              <div
                className={styles.modal_title}
                dangerouslySetInnerHTML={{
                  __html: tasksData[number as keyof TasksDataProps].title,
                }}
              />
              <div
                className={styles.paragraph}
                dangerouslySetInnerHTML={{
                  __html: tasksData[number as keyof TasksDataProps].paragraph,
                }}
              />
              <img
                className={styles.wrapper_close}
                src={`${urlImage}close.svg`}
                onClick={() => {
                  setShow(false);
                }}
              />
            </div>

            <div className={styles.container_link}>
              {number !== 13 ? (
                <div className={styles.text}>
                  <div className={styles.text_link}>
                    {number === 8 ? linkToCopy : link}
                  </div>
                </div>
              ) : null}
              {number != 13 ? (
                <button
                  onClick={() => {
                    copyLink(number === 9 ? link : linkToCopy);
                  }}
                  className={styles.button}
                >
                  Копировать
                </button>
              ) : (
                <button
                  onClick={() => {
                    copyLink(tasksData[13].copy);
                  }}
                  className={styles.button}
                >
                  Копировать
                </button>
              )}
              {success ?? null}
            </div>
          </div>
        </div>
      ) : null}
      {mobile ? (
        <div
          className={styles.modal_container}
          style={{
            backgroundImage: `url(${urlImage}mobile1.png)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "bottom center",
          }}
        >
          <div className={styles.modal_wrapper}>
            <div>
              <div
                className={styles.modal_title}
                dangerouslySetInnerHTML={{
                  __html: tasksData[number as keyof TasksDataProps].title,
                }}
              />
              <div
                className={styles.paragraph_m}
                dangerouslySetInnerHTML={{
                  __html: tasksData[number as keyof TasksDataProps].paragraph,
                }}
              />
            </div>
            <div className={styles.container_link}>
              {number !== 13 ? (
                <div className={styles.text}>
                  <div className={styles.text_link}>
                    {number === 9 ? link : linkToCopy}
                  </div>
                </div>
              ) : null}
              {number !== 13 ? (
                <button
                  onClick={() => {
                    copyLink(number === 9 ? link : linkToCopy);
                  }}
                  className={styles.button}
                >
                  Копировать
                </button>
              ) : (
                <button
                  onClick={() => {
                    copyLink(tasksData[13].copy);
                  }}
                  className={styles.button}
                >
                  Копировать
                </button>
              )}
              {success ? success : null}
              <img
                className={styles.wrapper_close_m}
                src={`${urlImage}close.svg`}
                onClick={() => {
                  setShow(false);
                }}
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>,
    document.getElementById("root")!
  );
}

export default React.memo(ModalTask);
