import React, { useState, useEffect, useCallback } from "react";
import {
  urlCheckTask,
  authorization,
  pathToSite,
  sign,
} from "../../utils/api/apiUrl";
import styles from "./BonusTask.module.css";

interface Props {
  id: number;
  title: string;
  description: string;
  url: string;
  img?: any;
}

export const BonusTask: React.FC<Props> = ({
  title,
  description,
  url,
  img,
  id,
}) => {
  const [isCheck, setIsCheck] = useState(false);
  const [myTaskState, setMyTaskState] = useState(false);
  const [newData, setNewData]: any = useState([]);
  const recordEvent = useCallback((name: string) => {
    ym(94652796, "reachGoal", name);
  }, []);

  function checkTask(taskId: any) {
    fetch(`${urlCheckTask}${taskId}`, {
      method: "POST",
      headers: {
        Authorization: `${authorization}`,
        Sign: `${sign}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setNewData(data);
      })
      .catch((error) => {
        console.error("Ошибка:", error);
      });
  }

  useEffect(() => {
    if (newData.ok) {
      setMyTaskState(true);
    }
  }, [newData]);
  return (
    <div className={styles.bonus_container}>
      <div className={styles.container}>
        <div className={styles.container_inner}>
          <div>
            <div
              className={styles.title}
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
          <div className={styles.footer}>
            {!isCheck && !myTaskState && (
              <a
                href={url}
                className={styles.button}
                target="_blank"
                onClick={() => {
                  setIsCheck(true);
                  recordEvent("bonus");
                  recordEvent(`${id}`);
                }}
              >
                Перейти
              </a>
            )}
            {isCheck && !myTaskState && (
              <button
                type="button"
                className={styles.button_check}
                onClick={() => {
                  checkTask(id);
                }}
              >
                Участвую
              </button>
            )}
            {myTaskState && <div className={styles.checked}>Выполнено</div>}
          </div>
        </div>
        {img && (
          <div
            style={{
              height: "inherit",
              position: "absolute",
              right: "0",
              bottom: "-1px",
              zIndex: "1",
            }}
          >
            <img
              src={`${pathToSite}${img}`}
              style={{ height: "inherit", maxHeight: "90px" }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
