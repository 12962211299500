import React, { useState, useEffect, useCallback } from "react";
import { ChallengesRegular } from "../ChallengesRegular/ChallengesRegular";
import { urlGetSpecialTask } from "../../utils/api/apiUrl";
import { TaskDaily } from "../TaskDaily/TaskDaily";
import { BonusTask } from "../BonusTask/BonusTask";
import { NoTasks } from "../NoTasks/NoTasks";
import ModalPage from "../ModalPages/ModalPage";
import { HELP_HTML } from "../../utils/const";
import useApiData from "../hooks/useApiData";
import { useMobile } from "../hooks/useMobile";
import useArrayState from "../hooks/useArrayState";

import styles from "./ActiveTasks.module.css";
import SpecialTask, { SpecialTaskType } from "../SpecialTask/SpecialTask";

interface Props {
  dataTasks: [];
  active: boolean;
  bonusTasks: [];
  weeklyTasks: [];
  timesTasks: [];
  currentDay: number;
  activeDayCurrentWeek: number;
  selected: number;
  activeDay?: number;
}

export const ActiveTasks: React.FC<Props> = ({
  dataTasks,
  active,
  bonusTasks,
  weeklyTasks,
  timesTasks,
  currentDay,
  activeDayCurrentWeek,
  selected,
}) => {






  const specialData = useApiData(urlGetSpecialTask);

  const mobile = useMobile();
  const arrayState = useArrayState();

  const [modalHelp, setModalHelp] = useState(false);
  const [specialTask, setSpecialTask] = useState<SpecialTaskType>();

  useEffect(() => {
    if (specialData[0]) {
      setSpecialTask(specialData[selected - 1]);
    }
  }, [specialData, selected]);

  const handleModalHelp = useCallback(
    () => setModalHelp(!modalHelp),
    [modalHelp]
  );

  return (
    <div className={styles.wrapper_main}>
      <div className={styles.main}>
        {modalHelp ? (
          <ModalPage
            title="Правила"
            subtitle={HELP_HTML}
            handleClick={handleModalHelp}
            mobile={mobile}
          />
        ) : null}

        {selected && specialTask?.name ? (
          <SpecialTask {...specialTask} />
        ) : null}

        <div className={styles.about}>
          Набери максимальное количество{" "}
          <span className={styles.about_cp}>СР</span>
          <br />и повысь общий рейтинг своего сообщества!
        </div>
        {dataTasks && Array.isArray(dataTasks) ? (
          <div className={styles.container_daily_tasks}>
            <div className={styles.title_daily_tasks}>Ежедневные задания</div>
            <div className={styles.container_daily_card}>
              <div className={styles.daily_tasks}>
                {dataTasks.map((item: any) => (
                  <TaskDaily
                    arrayStateNumber={arrayState.includes(item.number_task)}
                    id={item.id}
                    number={item.number_task}
                    title={item.name}
                    text={item.description}
                    cost={item.weight_fibonacci}
                    check="Выполнить"
                    key={item.id}
                    active={active}
                    url={item.url_task}
                    ended={item.ended}
                    done={item.done}
                    modalHelp={modalHelp}
                    setModalHelp={setModalHelp}
                    activeDayCurrentWeek={activeDayCurrentWeek}
                    currentDay={currentDay}
                  />
                ))}
              </div>
            </div>
          </div>
        ) : null}
      </div>

      {weeklyTasks ?? timesTasks ? (
        <ChallengesRegular
          title="Еженедельные задания"
          active={active}
          data={weeklyTasks}
          timesTasks={timesTasks}
          regularity="Еженедельно"
          modalHelp={modalHelp}
          setModalHelp={setModalHelp}
        />
      ) : null}
      {Array.isArray(bonusTasks) ? (
        <div className={styles.container_bonus}>
          <div className={styles.title_bonus}>Бонусные задания</div>
          <div className={styles.bonus_tasks}>
            {Array.isArray(bonusTasks)
              ? bonusTasks?.map((item: any) => (
                  <BonusTask
                    id={item.id}
                    title={item.name}
                    description={item.description}
                    key={item.id}
                    url={item.url_task}
                    img={item.image}
                  />
                ))
              : null}
          </div>
        </div>
      ) : null}
      {bonusTasks === null &&
      weeklyTasks === null &&
      timesTasks === null &&
      !Array.isArray(dataTasks) &&
      !active ? (
        <div className={styles.container_notasks}>
          <NoTasks selected={selected} />
        </div>
      ) : null}
    </div>
  );
};
