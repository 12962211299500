import { useContext } from "react";
import { Context } from "../../context";
import { urlGetUser, vkId, authorization, sign } from "../../utils/api/apiUrl";

const useCheckScoreCurrent = () => {
    const { scoreCurrent, setScoreCurrent } = useContext(Context);

    async function checkScoreSpecial() {
        await fetch(`${urlGetUser}?vk_id=${vkId}`, {
            method: "POST",
            headers: {
                Authorization: `${authorization}`,
                Sign: `${sign}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setTimeout(() => {
                    if (setScoreCurrent) setScoreCurrent(data.score_fibonacci);
                }, 1000);
            })
            .catch((error) => {
                console.error("Ошибка:", error);
            });
    }

    return { checkScoreSpecial, scoreCurrent };
};

export default useCheckScoreCurrent;
