import React, { useState } from 'react';
import styles from '../HeaderItem/HeaderItem.module.css';

interface Props {
    title: string;
    id: string;
    aria: string;
    select: string;
    setSelected: React.Dispatch<React.SetStateAction<any>>;
    selected: any;
}

export const HeaderItem: React.FC<Props> = ({ title, id, aria, select, setSelected, selected }) => {
    return (
        <div className={styles.container}
            id={id}
            aria-controls={aria}
            key={id}
            onClick={() => {
                setSelected(select);
            }}>{selected === select && <div className={styles.title_active}>{title}</div>}
            {selected != select && <div className={styles.title}>{title}</div>}
        </div>
    )
}
