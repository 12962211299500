import React, { useState, useEffect, useContext } from "react";
import styles from "./League.module.css";
import { LeaderboardItem } from "../LeaderboardItem/LeaderboardItem";
import { LeagueItem } from "../LegueItem/LeagueItem";
import SystemAlerts from "../SystemAlerts";
import { data } from "../LeaderboardItem/DataLeaderboardItem";
import {
  urlCheckChangePublic,
  authorization,
  sign,
  urlGetChange,
} from "../../utils/api/apiUrl";
import useApiData from "../hooks/useApiData";
import { routes } from "../../utils/routes";
import { useRouteNavigator } from "@vkontakte/vk-mini-apps-router";
import classNames from "classnames";
import { Context } from "../../context";
import { useMobile } from "../hooks/useMobile";

interface PropsLeaderboard {
  name: string;
  erv: string;
  rating: string;
  active_subscriptions: number;
  delta_subscriptions: number;
  url_public: string;
  url_hub: string;
}

interface Props {
  dataLeaderboard: PropsLeaderboard[];
  number: any;
}

export const League: React.FC<Props> = ({ dataLeaderboard, number }) => {
  const routeNavigator = useRouteNavigator();
  const mobile = useMobile();
  const [show, setShow]: any = useState(false);
  const [generalChange, setGeneralChange] = useState(false);
  const [canChange, setCanChange] = useState(false);
  const [date, setDate] = useState("");
  const dateChange = useApiData(urlGetChange);
  const { dataUser } = useContext(Context);

  useEffect(() => {
    function checkChange() {
      if (dataUser.public_id) {
        fetch(`${urlCheckChangePublic}${dataUser.public_id}`, {
          method: "POST",
          headers: {
            Authorization: `${authorization}`,
            Sign: `${sign}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            if (data?.Error === "already change") {
              setGeneralChange(false);
            } else if (data?.status) {
              setGeneralChange(true);
              setCanChange(data.status);
            } else if (
              data?.Error === "today it is impossible to change public"
            ) {
              setGeneralChange(true);
            }
          })
          .catch((error) => {
            console.error("Ошибка:", error);
          });
      } else {
        return;
      }
    }
    checkChange();
  }, []);

  useEffect(() => {
    if (dateChange[0]) {
      setDate(dateChange[0]["date"]);
    }
  }, [dateChange]);

  return (
    <div
      id="tab-content-leagueA"
      aria-labelledby="tab-leagueA"
      role="tabpanel"
      className={styles.container}
    >
      <div className={styles.header}>
        <div className={styles.header_team}>Сообщество</div>
        <div className={styles.header_inside}>
          {data?.map((item) => (
            <LeaderboardItem
              text={item.text}
              title={item.title}
              key={item.title}
              img={item.img}
              mobile={mobile}
            />
          ))}
        </div>
      </div>
      <div className={styles.list}>
        {dataLeaderboard?.map(
          (
            item: PropsLeaderboard,
            idx: number
          ) => (
            <LeagueItem
              name={item.name}
              erv={item.erv}
              active_subscriptions={item.active_subscriptions}
              delta_subscriptions={item.delta_subscriptions}
              total={item.rating}
              mobile={mobile}
              setShow={setShow}
              key={item.name}
              url={item.url_public}
              urlHub={item.url_hub}
              isFirst={idx === 0}
            />
          )
        )}
      </div>
      <div className={styles.footer}>
        {mobile && show && show}

        <div className={styles.wrapper_button}>
          {generalChange ? (
            <SystemAlerts
              canChange={canChange}
              subtitle={`Ты сможешь поменять команду только ${date}`}
            >
              <div
                className={classNames(
                  !canChange
                    ? styles.footer_button
                    : styles.footer_button_active
                )}
                onClick={() => {
                  if (canChange) {
                    routeNavigator.push(
                      routes.default_root.default_view.hub_panel
                    );
                  }
                }}
              >
                Сменить команду
              </div>
            </SystemAlerts>
          ) : null}
        </div>

        <div className={styles.number}>{number}</div>
      </div>
    </div>
  );
};
