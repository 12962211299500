import { useState, useEffect } from "react";
import styles from "./ModalFaffle.module.css";
import { urlFaffle } from "../../utils/api/apiUrl";
import useApiData from "../hooks/useApiData";

const ONE_MINUTE = 60;
const ONE_HOUR = ONE_MINUTE * 60;

const getRemainingTime = (endDate: string) => {
  const now = new Date();
  const formattedEndDate = new Date(endDate);
  const timeDifference = (formattedEndDate.getTime() - now.getTime()) / 1000;

  if (timeDifference < 0) return { hours: 0, minutes: 0, seconds: 0 };

  const hours = Math.floor(timeDifference / ONE_HOUR);
  const minutes = Math.floor((timeDifference % ONE_HOUR) / ONE_MINUTE);
  const seconds = Math.floor(timeDifference % ONE_MINUTE);

  return { hours, minutes, seconds };
};

export const ModalFaffle = () => {
  const dataFaffleApi = useApiData(`${urlFaffle}`);
  const endDate = dataFaffleApi.time;

  const [time, setTime] = useState<{
    hours: number;
    minutes: number;
    seconds: number;
  } | null>(null);

  let interval: NodeJS.Timeout;

  const tick = () => {
    setTime(() => {
      const newTime = getRemainingTime(endDate);

      if (
        newTime.hours === 0 &&
        newTime.minutes === 0 &&
        newTime.seconds === 0
      ) {
        clearInterval(interval);
      }

      return newTime;
    });
  };

  useEffect(() => {
    if (endDate) {
      interval = setInterval(tick, 1000);

      return () => clearInterval(interval);
    }
  }, [endDate]);

  return (
    <div className={styles.container}>
      <div className={styles.container_inner}>
        <div
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: dataFaffleApi.text }}
        />

        {time ? (
          <div className={styles.main}>
            <div className={styles.description}>
              До финальной трансляции осталось:
            </div>
            <div className={styles.time}>
              {String(time.hours).padStart(2, "0")}:
              {String(time.minutes).padStart(2, "0")}:
              {String(time.seconds).padStart(2, "0")}
            </div>
          </div>
        ) : null}
        <a href={dataFaffleApi.url} target="_blank" className={styles.button}>
          Смотреть
        </a>
      </div>
    </div>
  );
};
