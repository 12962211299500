import { useState, useEffect } from "react";
import { Root, View } from "@vkontakte/vkui";
import {
    useActiveVkuiLocation,
    useGetPanelForView,
    useRouteNavigator,
} from "@vkontakte/vk-mini-apps-router";
import { routes } from "./utils/routes";
import useApiData from "../src/components/hooks/useApiData";
import { urlDate, urlTurnir, urlTimeline } from "../src/utils/api/apiUrl";
import Hub from "./panels/Hub/Hub";
import Intro from "./panels/Intro/Intro";
import Home from "./panels/Home/Home";
import Timeline from "./panels/Timeline";
import { LoadPanel } from "./panels/LoadPanel/LoadPanel";
import { ErrorView } from "./ErrorView/ErrorView";
import ErrorBoundary from "./components/ErrorBoundary";
import { HEADERS } from "./utils/const";

import "./global.css";
import "@vkontakte/vkui/dist/vkui.css";

const App = () => {
    const routeNavigator = useRouteNavigator();
    const { view: activeView } = useActiveVkuiLocation();
    const activePanel = useGetPanelForView(
        routes.default_root.default_view.intro_panel.id
    );

    const [selected, setSelected] = useState(HEADERS[1].select);
    const [dataDay, setDataDay] = useState<{ day: number; week: number }>({
        day: 0,
        week: 0,
    });

    const dataDate = useApiData(urlDate);
    const dataTimeline: {
        id: number;
        week: string;
        day: string;
        subheading: string;
    }[] = useApiData(urlTurnir);
    const dataTurnir: { id: number; title: string; text: string }[] =
        useApiData(urlTimeline);

    useEffect(() => {
        if (dataDate) {
            setDataDay(dataDate);
        }
    }, [dataDate]);

    return (
        <ErrorBoundary routeNavigator={routeNavigator}>
            <Root
                nav={routes.default_root.default_view.id}
                activeView={activeView ?? routes.default_root.default_view.id}
            >
                <View
                    nav={routes.default_root.default_view.id}
                    activePanel={
                        activePanel ?? routes.default_root.default_view.id
                    }
                >
                    <LoadPanel
                        nav={routes.default_root.default_view.load_panel.id}
                    />
                    <Intro
                        nav={routes.default_root.default_view.intro_panel.id}
                        isFirst
                    />
                    <Timeline
                        nav={
                            routes.default_root.default_view.leaderboard_panel
                                .id
                        }
                        date={dataDay}
                        dataTimeline={dataTimeline}
                        dataTurnir={dataTurnir}
                        isFirst
                    />
                    <Hub nav={routes.default_root.default_view.hub_panel.id} />
                    <Home
                        nav={routes.default_root.default_view.home_panel.id}
                        selected={selected}
                        setSelected={setSelected}
                        dataDay={dataDay}
                        dataTimeline={dataTimeline}
                        dataTurnir={dataTurnir}
                    />
                </View>
                <View
                    nav={routes.default_root.error_view.id}
                    activePanel="error"
                >
                    <ErrorView nav={routes.default_root.error_view.error.id} />
                </View>
            </Root>
        </ErrorBoundary>
    );
};

export default App;
