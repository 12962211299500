import React, { useState, useEffect } from "react";
import { Challenges } from "../Challenges/Challenges";
import styles from './ChallengesRegular.module.css';

interface Props {
    title: string;
    active: boolean;
    data?: any;
    regularity?: string;
    timesTasks?: any;
    modalHelp?: any;
    setModalHelp?: any;
}

export const ChallengesRegular: React.FC<Props> = ({ title, active, data, regularity, timesTasks, modalHelp, setModalHelp }) => {
    const [bonus, setBonus]: any = useState([]);
    const [times, setTimes]: any = useState([]);


    useEffect(() => {
        function getTasks(data: any) {
            if (Array.isArray(data)) {
                const bonusTasks = data.map((item: any) => (
                    <Challenges
                        number={item.number_task}
                        id={item.id}
                        key={item.id}
                        title={item.name}
                        description={item.description}
                        cost={item.weight_fibonacci}
                        active={active}
                        regularity={regularity}
                        url={item.url_task}
                        done={item.done}
                        ended={item.ended}
                        countDone={item.countDone}
                        future={item.future}
                        modalHelp={modalHelp}
                        setModalHelp={setModalHelp} />

                ));
                return bonusTasks;
            } else {
                return
            }
        };
        setBonus(getTasks(data));
    }, [data])

    useEffect(() => {
        function getTimesTasks(data: any) {
            if (Array.isArray(data)) {
                const timesTasks = data.map((item: any) => (
                    <Challenges
                        number={item.number_task}
                        key={item.id}
                        id={item.id}
                        title={item.name}
                        description={item.description}
                        cost={item.weight_fibonacci}
                        active={active}
                        url={item.url_task}
                        done={item.done}
                        ended={item.ended}
                        totalDays={item.day_available}
                        doneDays={item.day_passed}
                        future={item.future}
                        modalHelp={modalHelp}
                        countDone={item.countDone}
                        setModalHelp={setModalHelp} />
                ));
                return timesTasks;
            } else {
                return
            }
        };
        if (timesTasks) {
            setTimes(getTimesTasks(timesTasks))
        } else {
            return
        }
    }, [timesTasks])

    return (
        <div className={styles.container}>
            <div className={styles.title}>{title}</div>
            <div className={styles.card_container}>
                {bonus ? bonus : null}
                {times ? times : null}
            </div>
        </div>
    )
}