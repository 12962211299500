import React, { useState, useCallback } from "react";
import { useRouteNavigator } from "@vkontakte/vk-mini-apps-router";
import { Panel, PanelHeader } from "@vkontakte/vkui";
import { routes } from "../../utils/routes";
import { urlImage, isMobile } from "../../utils/api/apiUrl";
import styles from "./Intro.module.css";
import ModalPage from "../../components/ModalPages/ModalPage";
import { HELP_HTML } from "../../utils/const";
import { useMobile } from "../../components/hooks/useMobile";

interface Props {
    nav: string;
    isFirst: boolean;
}

const Intro: React.FC<Props> = ({ nav, isFirst }) => {
    const routeNavigator = useRouteNavigator();
    const scoreImg = `${urlImage}score.png`;
    const mobile = useMobile();
    const [modalHelp, setModalHelp] = useState(false);

    const handleModalHelp = useCallback(
        () => setModalHelp(!modalHelp),
        [modalHelp]
    );

    return (
        <Panel nav={nav}>
            {isMobile ? (
                <PanelHeader
                    separator={false}
                    fixed={true}
                    style={{ zIndex: "1000" }}
                >
                    Community Hub Cup
                </PanelHeader>
            ) : null}
            {modalHelp ? (
                <ModalPage
                    title="Правила"
                    subtitle={HELP_HTML}
                    handleClick={handleModalHelp}
                    mobile={mobile}
                />
            ) : null}
            <div
                className={isFirst ? styles.container_first : styles.container}
            >
                <div className={styles.container_outer}>
                    <div className={styles.container_inner}>
                        <div className={styles.container_title}>
                            <div
                                className={
                                    isFirst ? styles.title_first : styles.title
                                }
                            >
                                Community Hub Cup
                            </div>
                        </div>
                        <div className={styles.container_card}>
                            <div
                                className={styles.card}
                                style={{
                                    backgroundImage: `url(${urlImage}man.png)`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center center",
                                }}
                            >
                                <div className={styles.card_title}>
                                    Выбирай
                                    <br />
                                    сообщество
                                </div>
                                <div className={styles.card_paragraph}>
                                    А на чьей стороне ты?
                                </div>
                            </div>
                            <div
                                className={styles.card}
                                style={{
                                    backgroundImage: `url(${scoreImg})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center center",
                                }}
                            >
                                <div className={styles.card_title}>
                                    Зарабатывай
                                    <br />
                                    опыт
                                </div>
                                <div className={styles.card_paragraph}>
                                    Получай опыт <br />
                                    за выполненные задания!
                                </div>
                            </div>
                            <div
                                className={styles.card}
                                style={{
                                    backgroundImage: `url(${urlImage}prizes.png)`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center center",
                                }}
                            >
                                <h4 className={styles.card_title}>
                                    Лутай призы
                                </h4>
                                <div className={styles.card_paragraph}>
                                    Индивидуальные подарки <br />и командные
                                    призы
                                </div>
                            </div>
                        </div>
                    </div>
                    {isFirst && (
                        <div className={styles.footer}>
                            <div className={styles.container_button}>
                                <button
                                    onClick={() => {
                                        routeNavigator.push(
                                            routes.default_root.default_view
                                                .leaderboard_panel
                                        );
                                    }}
                                    type="button"
                                    className={styles.button}
                                >
                                    Начать
                                </button>
                            </div>
                            <div className={styles.description_container}>
                                <div className={styles.description}>
                                    Нажимая "Начать",
                                    <br /> вы соглашаетесь с{" "}
                                    <span
                                        className={styles.link}
                                        onClick={handleModalHelp}
                                    >
                                        правилами
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Panel>
    );
};

export default Intro;
