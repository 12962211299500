import { useParams } from '@vkontakte/vk-mini-apps-router';
import {
  createHashParamRouter,
  withRouter,
  createModal,
  createPanel,
  createRoot,
  createView,
  RoutesConfig,
} from '@vkontakte/vk-mini-apps-router';
import { vkId } from './api/apiUrl';


// Создание массива объектов
export const routes = RoutesConfig.create([
  createRoot('default_root', [
    createView('default_view', [
      createPanel('load_panel', '/'),
      createPanel('intro_panel', `/intro_panel`),
      createPanel('hub_panel', `/hub_panel`),
      createPanel('home_panel', `/home_panel`),
      createPanel('leaderboard_panel', `/leaderboard`),
    ]),
    createView('error_view', [
      createPanel('error', '/error')
    ])
  ]),
]);

// Передача массива маршрутов при создании роутера 
export const router = createHashParamRouter(routes.getRoutes());
  // const newUrl = withRouter(RefIdComponent)