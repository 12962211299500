import { urlImage } from "../../utils/api/apiUrl";
import ModalPrize from "../ModalPrize";
import styles from "../ActiveTasks/ActiveTasks.module.css";
import useCheckSpecial from "../hooks/useCheckSpecial";
import useCheckScoreCurrent from "../hooks/useCheckScoreCurrent";
import { useEffect, useState } from "react";
import { useMobile } from "../hooks/useMobile";

export type SpecialTaskType = {
    name: string;
    text: string;
    deltatime: number;
    status: string;
    id: string;
    cp: number;
    winners: string;
    participants: string;
    url_manager: string;
    name_manager: string;
};

const SpecialTask: React.FC<SpecialTaskType> = ({
    name,
    text,
    deltatime,
    status,
    id,
    cp,
    winners,
    participants,
    url_manager,
    name_manager,
}) => {
    const { special, enough, checkSpecial } = useCheckSpecial();
    const { checkScoreSpecial, scoreCurrent } = useCheckScoreCurrent();
    const mobile = useMobile();

    const [show, setShow] = useState(false);
    const [systemAlert, setSystemAlert] = useState<React.ReactElement | null>(
        null
    );

    useEffect(() => {
        if (systemAlert) {
            setTimeout(setSystemAlert, 2000, null);
        }
    }, [systemAlert]);

    return (
        <div style={{ width: "calc(100% - 40px)", margin: "0 auto" }}>
            <div className={styles.container}>
                <div
                    style={{
                        display: "flex",
                        alignContent: "space-between",
                    }}
                    className={styles.content}
                >
                    <div>
                        <div className={styles.title}>{name}</div>
                        <div className={styles.description}>
                            <div
                                className={styles.description}
                                dangerouslySetInnerHTML={{
                                    __html: text,
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.footer}>
                        {!enough && systemAlert ? systemAlert : null}
                        {!enough && !status && deltatime != 0 ? (
                            <div
                                className={styles.button}
                                onClick={() => {
                                    setTimeout(() => {
                                        checkSpecial(id);
                                    }, 700);
                                    setTimeout(() => {
                                        checkScoreSpecial();
                                    }, 1000);
                                    setTimeout(() => {
                                        if (!special) {
                                            setSystemAlert(
                                                <div className={styles.wrapper}>
                                                    <div
                                                        className={
                                                            styles.alert_container
                                                        }
                                                    >
                                                        <img
                                                            className={
                                                                styles.wrapper_image
                                                            }
                                                            src={`${urlImage}/sistem.svg`}
                                                        />
                                                        <div>
                                                            Для участия в
                                                            розыгрыше необходимо
                                                            еще CP{" "}
                                                            {scoreCurrent
                                                                ? cp -
                                                                  scoreCurrent
                                                                : null}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.triangle
                                                        }
                                                    ></div>
                                                </div>
                                            );
                                        }
                                    }, 1200);
                                }}
                            >
                                Участвовать!
                            </div>
                        ) : null}
                        {enough || status ? (
                            <div className={styles.response}>
                                Ты принял участие в розыгрыше
                            </div>
                        ) : null}
                        {deltatime === 0 ? (
                            <div
                                className={styles.add}
                                onClick={() => {
                                    setShow(true);
                                }}
                            >
                                Список победителей
                            </div>
                        ) : null}
                        {deltatime != 0 ? (
                            <div className={styles.time}>
                                До конца розыгрыша: {deltatime}
                                {deltatime === 1 ? (
                                    <span className={styles.time}>
                                        &nbsp;день
                                    </span>
                                ) : deltatime > 1 && deltatime < 5 ? (
                                    <span className={styles.time}>
                                        &nbsp;дня
                                    </span>
                                ) : (
                                    <span className={styles.time}>
                                        &nbsp;дней
                                    </span>
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className={styles.image_container}>
                    <img src={`${urlImage}bird.png`} className={styles.image} />
                </div>
                {show ? (
                    <ModalPrize
                        setShow={setShow}
                        mobile={mobile}
                        winners={winners}
                        participants={participants}
                        url_manager={url_manager}
                        name_manager={name_manager}
                    />
                ) : null}
            </div>
        </div>
    );
};

export default SpecialTask;
